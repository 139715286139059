import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import toastOptions from '../../../../../utils/toastOptions';

import ButtonIcon from '../../../../atoms/control/iconButton';
import EditForm from '../../form/Edit';

import { StructureContext, PermissionsContext, HeaderType } from '..';

import {
    IBBDD, ISimpleEntry
} from '../../../../../types/bbdd';

import { UserContext } from '../../../../../App';

import { IPermissionsRights } from '../../../../../types/user';

import axiosInstance from '../../../../../utils/axiosIntercepter';

interface IProps {
    entry: ISimpleEntry;
    headers: Array<HeaderType>;
    updater: () => void;
}

const ItemTableRow: React.FC<IProps> = ({
    entry,
    headers,
    updater
}: IProps) => {
    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    const structure: IBBDD | undefined = useContext(StructureContext);
    const permissions: IPermissionsRights = useContext(PermissionsContext);

    const { role } = useContext(UserContext);

    const removeItem = async () => {
        try {
            if (structure) {
                await axiosInstance.delete(`/api/structures/${structure._id}/entries/${entry._id}`);

                toast.success('Item deleted', toastOptions);
                updater();
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleDownloadFile = async (fileId: string, fileName: string) => {
        try {
            if (!structure) { return; }
            const res = await axiosInstance({
                method: 'get',
                url: `/api/structures/${structure._id}/attachements/${fileId}?q=download`,
                responseType: 'blob',
                headers: {}
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName || 'file');
            document.body.appendChild(link);
            link.click();
        } catch {
            console.warn('Error downloading');
        }
    };

    const renderCreatedBy = (): string => {
        if (typeof entry._createdBy !== 'object') { return ''; }
        return entry._createdBy.username;
    };

    const renderItem = (header: HeaderType) => {
        const value = entry[header.apiName];
        if (header.type === 'field') {
            if (header.fieldType === 'boolean') {
                return (<Checkbox checked={entry[header.apiName] === true} />);
            }
            if (entry[header.apiName] === undefined) { return (<>{' '}</>); }
            if (header.fieldType === 'number') {
                return (<>{new Intl.NumberFormat('es-ES').format(value as number)}</>);
            }
            if (header.fieldType === 'formula') {
                return (<>{new Intl.NumberFormat('es-ES').format(value as number)}</>);
            }
            if (header.fieldType === 'percentage') {
                return (<>{new Intl.NumberFormat('es-ES', { style: 'percent' }).format(value as number)}</>);
            }
            if (header.fieldType === 'date') {
                const dateOptions = { day: 'numeric', month: 'numeric', year: 'numeric' } as const;
                return (<>{new Date(value as string).toLocaleDateString('es-ES', dateOptions)}</>);
            }
            if (header.fieldType === 'datetime') {
                return (<>{new Date(value as string).toLocaleString('es-ES')}</>);
            }
            if (header.fieldType === 'time') {
                return (<>{new Date(value as string).toLocaleTimeString('es-ES')}</>);
            }
            if (header.fieldType === 'file') {
                if (!(typeof value === 'object')) { return (<>{' '}</>); }
                if (!('name' in value) || !('_id' in value)) { return (<>{' '}</>); }
                return (
                    <>
                        <IconButton
                            component="span"
                            onClick={() => {
                                handleDownloadFile(value._id, value.name);
                            }}
                        >
                            <BrowserUpdatedIcon />
                        </IconButton>
                        {value.name}
                    </>
                );
            }
            return (<>{value}</>);
        }
        if (header.type === 'relation') {
            if (!Array.isArray(value)) { return (<>{' '}</>); }
            if (!header.relatedToField || !header.relatedToField.apiName) { return (<>{' '}</>); }
            const innerItemApiName = header.relatedToField.apiName;
            return (
                <>
                    {value.map((innerValue, index) => (
                        <span key={`${innerValue}-${index.toString()}-${entry._id}`}>
                            <>
                                {
                                    typeof innerValue !== 'string' && innerValue[innerItemApiName]
                                        ? innerValue[innerItemApiName]
                                        : ''
                                }
                                <br />
                            </>
                        </span>
                    ))}
                </>
            );
        }
        return (<>{' '}</>);
    };

    return (
        <>
            {permissions && structure && (
                <TableRow key={`row-${entry._id}`}>
                    <TableCell
                        align="center"
                        width={125}
                        key={entry._id.concat('-actions')}
                    >
                        {structure && structure.isLocked === false && (
                            <>
                                {permissions.canEdit && (
                                    <ButtonIcon
                                        iconName="pencil"
                                        key={entry._id.concat('-edit')}
                                        functionHandler={
                                            () => {
                                                setPopupOpen(true);
                                            }
                                        }
                                    />
                                )}
                                {permissions.canRemove && (
                                    <ButtonIcon
                                        key={entry._id.concat('-remove')}
                                        iconName="trash"
                                        functionHandler={removeItem}
                                    />
                                )}
                            </>
                        )}
                    </TableCell>
                    {headers.map((header: HeaderType, index: number) => (
                        <TableCell
                            sx={{ paddingY: '0.6rem' }}
                            align="center"
                            key={`${entry._id}-${index.toString()}`}
                        >
                            {renderItem(header)}
                        </TableCell>

                    ))}
                    {role === 'admin' && (
                        <TableCell
                            sx={{ paddingY: '0.6rem' }}
                            align="center"
                        >
                            {renderCreatedBy()}
                        </TableCell>
                    )}
                    {popupOpen && (
                        <EditForm
                            id={entry._id}
                            open={popupOpen}
                            title="Editar entrada"
                            onClose={() => { setPopupOpen(false); }}
                            onSubmit={updater}
                        />
                    )}
                </TableRow>
            )}
        </>
    );
};

export default ItemTableRow;
