import React, { startTransition, useEffect } from 'react';

import TextField from '@mui/material/TextField';

import { IField, IParams } from '../../../../../../types/bbdd';
import useAxiosFunction from '../../../../../../hooks/useAxiosFunction';

import { SpecialFieldBox } from './styles';

export interface IProps {
    field: IField;
    onChange: (params: IParams) => void;
}

const Formula: React.FC<IProps> = ({ field, onChange }) => {
    const {
        response, error, loading, axiosFetch: validateFunction
    } = useAxiosFunction();

    const handleChangeFormula = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            onChange({
                ...field.params,
                formula: e.target.value
            });
        });
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (field.params?.formula) {
                validateFunction({
                    url: '/api/helpers/validateFormula',
                    method: 'put',
                    data: {
                        formula: field.params?.formula
                    }
                });
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [field.params?.formula]);

    return (
        <SpecialFieldBox>
            <TextField
                variant="outlined"
                name="formulaField"
                label="Formula"
                fullWidth
                defaultValue={field.params?.formula || ''}
                onChange={(e) => { handleChangeFormula(e); }}
            />
            { !loading && response && !response.valid && (
                <p> Formato de formula inválido</p>
            )}
            { loading && (<p>Validando formula...</p>) }
            { error && !loading && (<p>{ error }</p>)}
        </SpecialFieldBox>
    );
};

export default Formula;
