import React, {
    useEffect,
    useState,
    createContext,
    useMemo,
    useContext,
    useCallback
} from 'react';

import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';

import ContentHeader from '../../../atoms/structure/contentHeader';
import Filters from './filters';

import NewForm from '../form/New';
import ItemTableRow from './row';

import {
    IBBDD, ISimpleEntry
} from '../../../../types/bbdd';

import { IPermissionsRights } from '../../../../types/user';

import axiosInstance from '../../../../utils/axiosIntercepter';
import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import { UserContext } from '../../../../App';

import {
    StyledBox, StyledTable, StyledSearchField
} from './styles';

import { getDisplayedItems, downloadCsv } from './functions';

const defaultRights = {
    canEdit: true,
    canRead: true,
    canRemove: true,
    canCreate: true
};
export const StructureContext = createContext<IBBDD | undefined>(undefined);
export const PermissionsContext = createContext<IPermissionsRights>(defaultRights);

export type HeaderType = {
    type: 'field' | 'relation',
    apiName: string,
    fieldName: string,
    fieldType: string,
    isDisplayField: boolean,
    relatedToField?: {
        fieldName: string,
        fieldType: string,
        apiName: string
    },
    relatedToStructure?: {
        apiName: string,
        name: string,
        _id: string
    }
}

const Table: React.FC<{ structure: IBBDD }> = ({ structure }) => {
    const [entries, setEntries] = useState<Array<ISimpleEntry>>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [filterQuery, setFilterQuery] = useState<{ [x: number]: string }>({});

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    const { role } = useContext(UserContext);

    const {
        response: permissionsReponse,
        loading: permissionsLoading,
        error: permissionsError,
        axiosFetch: fetchPermissions
    } = useAxiosFunction();

    const {
        response: formResponse,
        loading: formLoading,
        error: formError,
        axiosFetch: fetchForm
    } = useAxiosFunction();

    const refreshItems = async () => {
        const filterQueryString: string = Object.values(filterQuery).reduce((
            prev: string, curr: string
        ) => {
            if (!curr) { return prev; }
            return `${prev}&filter=${curr}`;
        }, '');
        try {
            if (structure) {
                const axiosPromise = await axiosInstance
                    .get(
                        `/api/structures/${structure._id}/entries?evaluate=true${filterQueryString === '?' ? '' : filterQueryString}&renderUser=true&populate=true`
                    );
                setEntries(() => axiosPromise.data);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const form: Array<HeaderType> = useMemo(() => {
        if (formLoading || formError || !formResponse) {
            return [];
        }
        return formResponse;
    }, [formResponse, formLoading, formError]);

    const displayItems: Array<ISimpleEntry> = useMemo((
    ) => getDisplayedItems(structure, entries, searchInput, form),
    [entries, searchInput, structure]);

    useEffect(() => {
        if (structure) {
            refreshItems();
        }
    }, [structure]);

    const permissions = useMemo(() => {
        if (permissionsLoading || permissionsError || !permissionsReponse) {
            return defaultRights;
        }
        return permissionsReponse;
    }, [permissionsReponse, permissionsLoading, permissionsError]);

    const displayFieldIndex: number = useMemo(() => {
        if (!form) { return 2; }
        const displayIndex = form.findIndex((
            value
        ) => value.isDisplayField === true);

        if (displayIndex < 0) { return 2; }

        return displayIndex + 2;
    }, [form]);

    const printHeaders = useCallback(() => {
        if (!form || !form.length) { return (<tr />); }
        const getHeaderName = (header: HeaderType) => {
            if (header.type === 'field') { return header.fieldName; }
            if (header.type === 'relation') { return header.relatedToStructure?.name; }
            return '';
        };
        return (
            <tr key="headers">
                <TableCell align="center" key="Actions">
                    <strong>{' '}</strong>
                </TableCell>
                {form.map((header) => (
                    <TableCell align="center" key={header.apiName}>
                        <strong>{getHeaderName(header)}</strong>
                    </TableCell>
                ))}

                { role === 'admin' && (
                    <TableCell align="center">
                        <strong> Usuario </strong>
                    </TableCell>
                )}
            </tr>
        );
    }, [form]);

    const handleChangePage = useCallback((_event: any, newPage: number) => {
        setPage(newPage);
    }, [setPage]);

    const handleChangeRowsPerPage = useCallback((event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, [setRowsPerPage, setPage]);

    const handleChangeFilterData = useCallback((
        filterValue: string, index: number | string
    ): void => {
        setFilterQuery((prev: { [x: number]: string }) => (
            { ...prev, [index]: filterValue }
        ));
    }, [setFilterQuery]);

    useEffect(() => {
        if (structure) {
            fetchForm({ url: `/api/structures/${structure._id}/form`, method: 'get' });
            fetchPermissions({ url: `/api/state/platform/structures/${structure._id}/permissions`, method: 'get' });
        }
    }, [structure, fetchForm, fetchPermissions]);

    return (
        <Box width="100%" overflow="auto" display="inline-flex" flexDirection="column" gap={0}>
            <ContentHeader
                title={structure?.name}
                counter={entries?.length || 0}
                buttonText="Nueva entrada"
                buttonDisabled={structure?.isLocked || !permissions.canCreate}
                onClick={() => { setPopupOpen(true); }}
            >
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <StyledSearchField
                        fullWidth
                        id="search"
                        value={searchInput}
                        variant="outlined"
                        onChange={(e) => { setSearchInput(e.target.value); }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                downloadCsv(structure, entries, form);
                            }}
                        >
                            Exportar
                        </Button>
                    </Box>
                </Box>
            </ContentHeader>
            {structure && (
                <Box sx={{
                    display: 'flex',
                    m: 2,
                    gap: 2,
                    flexWrap: 'wrap',
                    alignItems: 'center'
                }}
                >
                    <Filters
                        onChange={handleChangeFilterData}
                        structure={structure}
                        refresh={refreshItems}
                    />
                </Box>
            )}

            <StyledBox flexGrow={1}>
                <StructureContext.Provider value={structure}>
                    <PermissionsContext.Provider value={permissions}>
                        <TableContainer sx={{ width: '100%', overflowX: 'scroll' }}>

                            <StyledTable displayIndex={displayFieldIndex} cellSpacing={0} aria-label="simple table">
                                <TableHead>
                                    {structure && (
                                        <>{printHeaders()}</>
                                    )}
                                </TableHead>
                                <TableBody>
                                    {structure && form && displayItems && displayItems
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((
                                            // entry: IEntry
                                            entry: ISimpleEntry
                                        ) => (
                                            <ItemTableRow
                                                headers={form}
                                                entry={entry}
                                                key={`Row-${entry._id}`}
                                                updater={refreshItems}
                                            />
                                        ))}
                                </TableBody>

                            </StyledTable>
                            {popupOpen && (
                                <NewForm
                                    open={popupOpen}
                                    title="Nueva entrada"
                                    onClose={() => { setPopupOpen(false); }}
                                    onSubmit={refreshItems}
                                />
                            )}
                        </TableContainer>
                    </PermissionsContext.Provider>
                </StructureContext.Provider>
                {displayItems && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={displayItems.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </StyledBox>

        </Box>
    );
};

export default Table;
