import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Target from '../../components/molecules/imports/target';
import Source from '../../components/molecules/imports/source';
import Validation from '../../components/molecules/imports/validation';

import axios from '../../utils/axiosIntercepter';
import toastOptions from '../../utils/toastOptions';

const Imports: React.FC = () => {
    const [sourceData, setSourceData] = useState<Array<Record<string, any>>>();
    const [targetFields, setTargetFields] = useState <Array<{
        _id: string,
        fieldName: string,
        apiName: string,
        fieldType: string
    }>>();
    const [targetId, setTargetId] = useState<string>();
    const [targetData, setTargetData] = useState<Array<Record<string, any>>>();

    const handleChangeSourceData = (data: Array<Record<string, any>>) => {
        setSourceData(data);
        setTargetFields(undefined);
        setTargetId(undefined);
        setTargetData(undefined);
    };

    const handleChangeFields = (fields: Array<{
        _id: string,
        fieldName: string,
        apiName: string,
        fieldType: string
    }>, target: string) => {
        setTargetFields(fields);
        setTargetId(target);
        setTargetData(undefined);
    };

    const handleChangeTargetData = (data: Array<Record<string, any>>) => {
        setTargetData(data);
    };

    const handleImportData = async () => {
        if (!targetData || !targetId) { return; }

        for (let i = 0; i < targetData.length; i += 1) {
            const row = targetData[i];
            try {
                // eslint-disable-next-line no-await-in-loop
                await axios.post(`/api/structures/${targetId}/entries`, {
                    data: row
                });
            } catch {
                toast.error('Error al importar el registro', toastOptions);
            }
        }

        toast.success('Registros importados', toastOptions);
    };

    return (
        <Box width="100%" height="100%" padding="2rem">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Source onLoad={handleChangeSourceData} />
                </Grid>
                {sourceData && (
                    <Grid item xs={12} md={4}>
                        <Target onLoad={handleChangeFields} />
                    </Grid>
                )}
                {sourceData && targetFields && targetId && (
                    <Grid item xs={12} md={4}>
                        <Validation
                            targetId={targetId}
                            fields={targetFields}
                            data={sourceData}
                            onLoad={handleChangeTargetData}
                        />
                    </Grid>
                )}
            </Grid>
            <Box display="flex" justifyContent="center" width="100%">
                {targetData && (
                    <Button
                        variant="contained"
                        onClick={handleImportData}
                    >
                        Importar
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default Imports;
