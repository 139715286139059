import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import toastOptions from '../../../../utils/toastOptions';
import axios from '../../../../utils/axiosIntercepter';
import Form from '.';
import { IProps as ModalProps } from '../../sideModal';

import { IBaseBBDD } from '../../../../types/bbdd';

const initialStructure: IBaseBBDD = {
    name: '',
    apiName: '',
    isHidden: false,
    isLocked: false,
    fields: [{
        fieldName: 'ID',
        fieldType: 'string',
        isKeyField: true,
        required: true,
        shown: true,
        order: 1
    }],
    relations: []
};

const NewForm: React.FC<ModalProps> = ({ onSubmit, ...rest }) => {
    const onSubmitNewAction = useCallback(async (structure: IBaseBBDD) => {
        try {
            await axios.post('/api/structures', { data: structure });
            toast.success('Dataset created', toastOptions);
            onSubmit?.();
        } catch (err) {
            let message = 'Error';
            if (err instanceof Error) {
                message = err.message;
            }
            console.warn(message);
        }
    }, []);
    return (
        <Form
            onSubmit={onSubmitNewAction}
            structure={initialStructure}
            {...rest}
        />
    );
};

export default NewForm;
