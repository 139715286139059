import React, { useState, useEffect, useContext } from 'react';
import Stack from '@mui/material/Stack';
import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import Modal, { IProps as ModalProps } from '../../sideModal';
import { StructureContext } from '../table';

import {
    ISimpleEntry, IBBDD, IEmptySimpleEntry
} from '../../../../types/bbdd';

import { useURIContext } from '../../../../utils/URIContext';
import RenderField from '../../../../utils/formFieldRenderer';
import Relation from './segments/Relation';

export interface IProps extends ModalProps {
    entry: ISimpleEntry | IEmptySimpleEntry;
    children?: React.ReactNode;
}

const Form: React.FC<IProps> = ({
    open,
    entry,
    children,
    ...rest
}) => {
    const [form, setForm] = useState<Array<any>>([]);
    const [entryValue, setEntryValue] = useState<ISimpleEntry | IEmptySimpleEntry>(entry);
    const { baseURI } = useURIContext();
    const structure: IBBDD | undefined = useContext(StructureContext);

    const {
        response, loading, error, axiosFetch
    } = useAxiosFunction();

    const handleChangeField = (modValue: any, apiName: string) => {
        setEntryValue(() => {
            const copy = entry;
            copy[apiName] = modValue;
            return { ...copy };
        });
    };

    const handleChangeRelation = (modValue: Array<string>, apiName: string) => {
        setEntryValue(() => {
            const copy = entry;
            copy[apiName] = modValue;
            return { ...copy };
        });
    };

    const renderField = (field: any) => {
        if (!structure) return (<></>);
        if (field.type === 'field') {
            return (
                <RenderField
                    key={field._id}
                    initialValue={entryValue[field.apiName]}
                    fieldType={field.fieldType}
                    fieldName={field.fieldName}
                    params={field.params}
                    structureId={structure._id}
                    setter={(value) => { handleChangeField(value, field.apiName); }}
                />

            );
        }
        if (field.type === 'relation' && structure) {
            return (
                <Relation
                    apiName={field.apiName}
                    key={field._id}
                    entry={entryValue}
                    onChange={(value) => {
                        handleChangeRelation(value, field.apiName);
                    }}
                />

            );
        }
        return (<></>);
    };

    useEffect(() => {
        axiosFetch({ url: `${baseURI}/form`, method: 'get' });
    }, []);

    useEffect(() => {
        setForm(response || []);
    }, [response]);

    return (
        <Modal
            {...rest}
            open={open}
        >
            <Stack spacing={3} mt={3}>
                {structure && form && !loading && !error && form.map((value) => (
                    renderField(value)
                ))}
                {children}
            </Stack>
        </Modal>
    );
};

export default Form;
