import React, {
    useState,
    useCallback,
    startTransition,
    useContext
} from 'react';

import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Modal, { IProps as ModalProps } from '../../sideModal';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import StyledContainer from './styles';

import { UserContext } from '../../../../App';

export interface IProps extends Omit<ModalProps, 'onSubmit'> {
    onSubmit: () => void;
}

const New: React.FC<IProps> = ({
    onSubmit, ...rest
}) => {
    const [name, setName] = useState<string>('');
    const [isPlatformAdmin, setIsPlatformAdmin] = useState<boolean>(false);
    const { platform } = useContext(UserContext);

    const { axiosFetch } = useAxiosFunction();

    const handleSubmit = useCallback(async () => {
        if (!platform) { return; }
        await axiosFetch({
            url: `/api/platforms/${platform}/roles`,
            method: 'post',
            data: {
                name,
                isPlatformAdmin
            }
        });
        onSubmit();
    }, [onSubmit, axiosFetch, name, isPlatformAdmin, platform]);

    const handleChangeName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setName(() => e.target.value);
        });
    };

    const handleChangeIsAdmin = () => {
        startTransition(() => {
            setIsPlatformAdmin(!isPlatformAdmin);
        });
    };

    return (
        <Modal
            onSubmit={handleSubmit}
            {...rest}
        >
            <StyledContainer>
                <TextField
                    variant="outlined"
                    name="name"
                    label="Nombre"
                    fullWidth
                    defaultValue={name}
                    onChange={(e) => { handleChangeName(e); }}
                />
            </StyledContainer>
            <FormGroup>
                <FormControlLabel
                    control={(
                        <Switch
                            name="isAdmin"
                            defaultChecked={false}
                            onChange={() => handleChangeIsAdmin()}
                        />
                    )}
                    label="Administrador"
                />
            </FormGroup>

        </Modal>
    );
};

export default New;
