import React, { useContext, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';

import { UserContext } from '../../App';
import useAxiosFunction from '../../hooks/useAxiosFunction';

const UserProperties: React.FC = () => {
    const [properties, setProperties] = useState<Array<string>>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const { platform } = useContext(UserContext);

    const { response, axiosFetch } = useAxiosFunction();
    const { axiosFetch: putFunction } = useAxiosFunction();
    const { axiosFetch: removeFunction } = useAxiosFunction();

    const handlePushProperty = async () => {
        if (!platform) { return; }
        const copy = [...properties, inputValue];
        setProperties(() => [...copy]);
        setInputValue('');

        await putFunction({
            url: `/api/platforms/${platform}/preferences/defaultUserAttributes`,
            method: 'put',
            data: {
                defaultUserAttributes: copy
            }
        });
    };

    const handleRemoveProperty = async (index: number) => {
        if (!platform) { return; }
        const copy = properties.filter(((_, ind: number) => ind !== index));
        setProperties(() => [...copy]);
        await removeFunction({
            url: `/api/platforms/${platform}/preferences/defaultUserAttributes`,
            method: 'put',
            data: {
                defaultUserAttributes: copy
            }
        });
    };

    useEffect(() => {
        if (platform) {
            axiosFetch({ url: `/api/platforms/${platform}/preferences/defaultUserAttributes`, method: 'get' });
        }
    }, [platform]);

    useEffect(() => {
        if (response) {
            setProperties(() => response);
        } else {
            setProperties([]);
        }
    }, [response]);

    return (
        <Box
            sx={{
                width: '100%'
            }}
        >
            <Paper elevation={4} sx={{ padding: '1rem 0', margin: '0.5rem 0' }}>
                <Typography variant="h4" align="center">
                    Propiedades por defecto de los usuarios
                </Typography>
            </Paper>
            <Paper elevation={4} sx={{ padding: '1rem', margin: '0.5rem 0' }}>
                <List>
                    {properties.map((property: string, index: number) => (
                        <ListItem
                            key={property}
                            secondaryAction={(
                                <>
                                    <IconButton
                                        aria-label="remove"
                                        onClick={() => { handleRemoveProperty(index); }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </>
                            )}
                        >
                            <Typography sx={{
                                width: '80%',
                                overflowWrap: 'break-word'
                            }}
                            >
                                {property}
                            </Typography>

                        </ListItem>
                    ))}
                </List>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Nuevo"
                    sx={{
                        margin: '0.5rem 0'
                    }}
                    value={inputValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputValue(e.target.value);
                    }}
                />
                <Box sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        disabled={!inputValue}
                        onClick={handlePushProperty}
                    >
                        Añadir
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default UserProperties;
