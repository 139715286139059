import React, {
    useState,
    useEffect,
    useMemo,
    useCallback
} from 'react';

import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import Topbar from '../../components/layout/topbar';
import PlatformCard from './card';
import { AddButtonIcon } from '../../components/atoms/control/iconButton';

import NewForm from '../../components/molecules/platform/form/New';

import axiosInstance from '../../utils/axiosIntercepter';
import useAxiosFunction from '../../hooks/useAxiosFunction';

import '../../styles/components/authentication/platformSelector.scss';
import * as S from './styles';

export interface IPlat {
    name: string;
    description: string;
    subtitle: string;
    isLocked: boolean;
    isHidden: boolean;
    isFilteredByClassifier: boolean;
    [x: string]: any;
}

interface IIdPlat extends IPlat {
    _id: string;
}

const Platforms: React.FC<{}> = () => {
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [searchInput, setSearchInput] = useState<string>('');

    const {
        response: platformsResponse,
        loading: platformsLoading,
        error: platformsError,
        axiosFetch: getPlatforms
    } = useAxiosFunction();

    const {
        response: rolesResponse,
        loading: rolesLoading,
        error: rolesError,
        axiosFetch: getRoles
    } = useAxiosFunction();

    const {
        response: userResponse,
        loading: userLoading,
        error: userError,
        axiosFetch: getUser
    } = useAxiosFunction();

    const platforms: Array<IIdPlat> | undefined = useMemo(() => {
        if (!platformsResponse || platformsLoading || platformsError) {
            return undefined;
        }

        return platformsResponse;
    }, [platformsResponse, platformsLoading, platformsError]);

    const displayedPlatforms: Array<IIdPlat> = useMemo(() => {
        if (!platforms) {
            return [];
        }

        if (!searchInput) {
            return platforms;
        }

        return platforms.filter((
            platform
        ) => platform.name.toLowerCase().includes(searchInput.toLowerCase()));
    }, [platforms, searchInput]);

    const roles: Array<{
        _id: string,
        platformId: string,
        isPlatformAdmin: boolean
    }> | undefined = useMemo(() => {
        if (!rolesResponse || rolesLoading || rolesError) {
            return undefined;
        }

        return rolesResponse.roles;
    }, [rolesResponse, rolesLoading, rolesError]);

    const username: string = useMemo(() => {
        if (!userResponse || userLoading || userError) {
            return '';
        }

        return userResponse.username;
    }, [userResponse, userLoading, userError]);

    const isSuperUser: boolean = useMemo(() => {
        if (!rolesResponse || rolesLoading || rolesError) {
            return false;
        }
        return rolesResponse.admin;
    }, [rolesResponse, rolesLoading, rolesError]);

    const editablePlatformsIds: Array<string> = useMemo(() => {
        if (!roles || !platforms) { return []; }

        if (isSuperUser) {
            return platforms.map((platform) => platform._id);
        }

        const editablePlatforms = roles
            .filter((role) => role.isPlatformAdmin)
            .map((role) => role.platformId);

        return editablePlatforms;
    }, [platforms, roles, isSuperUser]);

    const getData = useCallback(() => {
        getPlatforms({ url: '/api/platforms', method: 'get' });
        getRoles({ url: '/api/state/user/roles/', method: 'get' });
    }, [getPlatforms, getRoles]);

    useEffect(() => {
        if (getData) { getData(); }
    }, [getData]);

    useEffect(() => {
        if (getUser) {
            getUser({ url: '/api/state/user', method: 'get' });
        }
    }, [getUser]);

    const navigate = useNavigate();

    const printCard = useCallback((platform: IIdPlat) => {
        const isPlatformEditable = editablePlatformsIds.includes(platform._id);
        return (
            <PlatformCard
                key={platform._id}
                initialPlatform={platform}
                updater={getData}
                editable={isPlatformEditable}
            />
        );
    }, [editablePlatformsIds, getData]);

    const requestAccess = useCallback(async (platformId: string) => {
        try {
            await axiosInstance.get(`/api/platforms/${platformId}/access`);
            console.warn('navigate');
            navigate('/');
        } catch {
            console.warn('Error navigating to home');
        }
    }, [navigate]);

    useEffect(() => {
        if (!isSuperUser && platforms && Array.isArray(platforms) && platforms.length === 1) {
            requestAccess(platforms[0]._id);
        }
    }, [isSuperUser, platforms]);

    return (
        <div style={{
            flexGrow: 1, display: 'flex', flexDirection: 'column'
        }}
        >
            <Topbar username={username} />
            <S.Page>
                <S.Head>
                    {username && (
                        <S.Title>
                            PLATAFORMAS DE
                            {' '}
                            {username.toUpperCase()}
                        </S.Title>
                    )}
                    <TextField
                        fullWidth
                        id="search"
                        value={searchInput}
                        variant="outlined"
                        onChange={(e) => { setSearchInput(e.target.value); }}
                        sx={{
                            marginTop: 4,
                            height: '3.5em',
                            backgroundColor: '#dadde0',
                            color: 'grey',
                            borderRadius: '25px',

                            borderColor: '#dadde0',
                            '& .MuiOutlinedInput-root': {
                                '& > fieldset': {
                                    border: 'none'
                                }
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <div style={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center'
                                }}
                                >
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ marginLeft: 20 }} />
                                    </InputAdornment>
                                </div>
                            )
                        }}
                    />
                    {searchInput && (
                        <div style={{ margin: '1rem 0' }}>
                            {displayedPlatforms.length}
                            {' '}
                            resultados
                        </div>

                    )}
                </S.Head>

                <S.Container>
                    {displayedPlatforms && displayedPlatforms.map((v: any) => printCard(v))}
                    <S.AddButtonPanel>
                        {isSuperUser && (
                            <AddButtonIcon
                                iconSize="4x"
                                functionHandler={() => setPopupOpen(!popupOpen)}
                            />
                        )}
                    </S.AddButtonPanel>
                </S.Container>

                {popupOpen && (
                    <NewForm
                        onClose={() => { setPopupOpen(false); }}
                        onSubmit={() => { getData(); setPopupOpen(false); }}
                        open={popupOpen}
                        title="Nueva Plataforma"
                    />
                )}
            </S.Page>
        </div>
    );
};

export default Platforms;
