import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useContext
} from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import IconButton from '@mui/material/IconButton';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Edit from '../form/Edit';

import { IRole, IPermission } from '../../../../types/user';
import useAxiosFunction from '../../../../hooks/useAxiosFunction';
import { UserContext } from '../../../../App';

export interface IProps {
    roleId: string;
    structures: Array<{_id: string, name: string}>;
    refetch: () => void;
}

const Row: React.FC<IProps> = ({ roleId, structures, refetch }) => {
    const { platform } = useContext(UserContext);
    const [open, setOpen] = useState<boolean>(false);

    const {
        response: responseRole,
        loading: loadingRole,
        error: errorRole,
        axiosFetch: getRole
    } = useAxiosFunction();

    const {
        response: responsePermissions,
        loading: loadingPermissions,
        error: errorPermissions,
        axiosFetch: getPermissions
    } = useAxiosFunction();

    const {
        loading: loadingPermiSave,
        axiosFetch: putPermiSave
    } = useAxiosFunction();

    const {
        axiosFetch: removeFetch
    } = useAxiosFunction();

    const role: IRole | undefined = useMemo(() => {
        if (!responseRole || loadingRole || errorRole) { return undefined; }
        return responseRole;
    }, [responseRole, loadingRole, errorRole]);

    const permissions: Array<IPermission> | undefined = useMemo(() => {
        if (!responsePermissions || loadingPermissions || errorPermissions || !role) {
            return undefined;
        }
        return responsePermissions;
    }, [responsePermissions, loadingPermissions, errorPermissions, role]);

    const fetchRole = useCallback(() => {
        if (platform) {
            getRole({
                url: `/api/platforms/${platform}/roles/${roleId}`,
                method: 'get'
            });
        }
    }, [platform]);

    const fetchPermissions = useCallback(() => {
        if (platform && role && role._id) {
            getPermissions({
                url: `/api/platforms/${platform}/roles/${role._id}/permissions`,
                method: 'get'
            });
        }
    }, [platform, role]);

    const handleRemoveRole = useCallback(async () => {
        if (!platform || !role || !role._id) { return; }
        await removeFetch({
            url: `/api/platforms/${platform}/roles/${roleId}`,
            method: 'delete'
        });

        refetch();
    }, [role, platform, removeFetch, refetch]);

    const handleChangePermission = useCallback(async (
        permissionId: string, property: string, value: boolean
    ) => {
        if (!platform || !role || !role._id) { return; }
        await putPermiSave({
            url: `/api/platforms/${platform}/roles/${role._id}/permissions/${permissionId}`,
            method: 'put',
            data: { [property]: value }
        });
        fetchRole();
    }, [platform, role]);

    const getPermissionByStructure = (structureId: string): IPermission | undefined => {
        if (!permissions || permissions.length === 0) { return undefined; }

        const strPermission = permissions.find((
            permission: IPermission
        ) => permission.structureId === structureId);

        return strPermission;
    };

    const printPermission = (structureId: string) => {
        const permission = getPermissionByStructure(structureId);

        if (!permission) { return undefined; }

        return (
            <>
                <IconButton
                    onClick={() => {
                        handleChangePermission(permission._id, 'canRead', !permission.canRead);
                    }}
                    disabled={loadingPermiSave || loadingRole}
                    aria-label="view"
                >
                    {permission.canRead ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
                </IconButton>
                <IconButton
                    onClick={() => {
                        handleChangePermission(permission._id, 'canEdit', !permission.canEdit);
                    }}
                    disabled={loadingPermiSave || loadingRole}
                    aria-label="edit"
                >
                    {permission.canEdit ? (<EditIcon />) : (<EditOffIcon />)}
                </IconButton>
                <IconButton
                    onClick={() => {
                        handleChangePermission(permission._id, 'canCreate', !permission.canCreate);
                    }}
                    disabled={loadingPermiSave || loadingRole}
                    aria-label="create"
                >
                    {permission.canCreate ? (<PlaylistAddIcon />) : (<PlaylistRemoveIcon />)}
                </IconButton>
                <IconButton
                    onClick={() => {
                        handleChangePermission(permission._id, 'canRemove', !permission.canRemove);
                    }}
                    disabled={loadingPermiSave || loadingRole}
                    aria-label="remove"
                >
                    {permission.canRemove ? (<DeleteIcon />) : (<DeleteForeverIcon />)}
                </IconButton>
            </>
        );
    };

    useEffect(() => { fetchRole(); }, [fetchRole]);
    useEffect(() => { fetchPermissions(); }, [fetchPermissions]);

    return (
        <>
            { role && (
                <TableRow key={role._id}>
                    <TableCell>
                        <IconButton
                            component="span"
                            onClick={handleRemoveRole}
                        >
                            <DeleteIcon />
                        </IconButton>
                        <IconButton
                            component="span"
                            onClick={() => { setOpen(true); }}
                        >
                            <EditIcon />
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        <strong>{role.name}</strong>
                    </TableCell>
                    { structures && structures.map((str) => (
                        <TableCell key={`${role._id}-${str._id}-cell`}>
                            {printPermission(str._id)}
                        </TableCell>
                    )) }
                    { open && (
                        <Edit
                            open={open}
                            initialName={role.name}
                            initialAdmin={role.isPlatformAdmin || false}
                            roleId={role._id}
                            onClose={() => { setOpen(false); }}
                            onSubmit={() => { fetchRole(); setOpen(false); }}
                            title="Editar rol"
                        />
                    )}
                </TableRow>
            )}
        </>
    );
};

export default Row;
