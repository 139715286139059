import React, { useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import FolderIcon from '@mui/icons-material/Folder';

import useAxiosFunction from '../../../hooks/useAxiosFunction';

import { UserContext } from '../../../App';

import { StyledPathContainer, StyledPathLink, StylePathLinkText } from './styles';

export interface IProps {
    root?: boolean;
}

const Path: React.FC<IProps> = ({ root }) => {
    const { folderId } = useParams();

    const { platform } = useContext(UserContext);

    const {
        response: pathResponse,
        loading: pathLoading,
        error: pathError,
        axiosFetch: getPath
    } = useAxiosFunction();

    const path = useMemo(() => {
        if (!pathResponse || pathLoading || pathError) {
            return undefined;
        }
        return pathResponse;
    }, [pathResponse, pathLoading, pathError]);

    useEffect(() => {
        if (folderId && platform && getPath) {
            getPath({
                url: `/api/platforms/${platform}/folders/${folderId}/path`,
                method: 'get'
            });
        }
    }, [folderId, platform, getPath]);

    return (
        <div style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '1em'
        }}
        >

            <StyledPathContainer>
                <StyledPathLink
                    to="/documentacion/"
                >
                    <StylePathLinkText variant="h6">
                        Documentación
                        <br />
                    </StylePathLinkText>
                </StyledPathLink>
                { !root && path && path.length > 0 && path.map(
                    (pathItem: any, index: number) => (
                        <Box display="block">
                            <StyledPathLink
                                key={pathItem.id}
                                to={`/documentacion/${pathItem.id}`}
                            >
                                <StylePathLinkText
                                    variant="h6"
                                    style={{
                                        marginLeft: `${20 * (pathItem.depth + 1)}px`
                                    }}
                                >
                                    {' '}
                                    <span className="material-icons" style={{ color: 'primary' }}>subdirectory_arrow_right</span>
                                    <FolderIcon color="primary" sx={{ fontSize: 23, alignItems: 'stretch' }} />
                                    {' '}
                                    { index === path.length - 1
                                        ? pathItem.name
                                        : `${pathItem.name}`}
                                </StylePathLinkText>
                            </StyledPathLink>
                        </Box>
                    )
                ) }
            </StyledPathContainer>
        </div>
    );
};

export default Path;
