import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useContext
} from 'react';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';

import ContentHeader from '../../../atoms/structure/contentHeader';
import Row from './Row';
import New from '../form/New';

import { IRole } from '../../../../types/user';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import { UserContext } from '../../../../App';

import { StyledTable } from '../../../atoms/structure/table/styles';

const Table: React.FC = () => {
    const { _id: userId, role: userRole, platform } = useContext(UserContext);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [open, setOpen] = useState<boolean>(false);

    const {
        response: responseRoles,
        error: errorRoles,
        loading: loadingRoles,
        axiosFetch: getRoles
    } = useAxiosFunction();

    const {
        response: responseStructures,
        error: errorStructures,
        loading: loadingStructures,
        axiosFetch: getStructures
    } = useAxiosFunction();

    const fetchData = useCallback(() => {
        if (userId && userRole && userRole === 'admin' && platform) {
            getRoles({
                url: `/api/platforms/${platform}/roles`,
                method: 'get'
            });
            getStructures({
                url: '/api/structures',
                method: 'get'
            });
        }
    }, [getRoles, getStructures, userId, userRole, platform]);

    const roles: Array<IRole> | undefined = useMemo(() => {
        if (!responseRoles || loadingRoles || errorRoles) { return undefined; }
        return responseRoles;
    }, [responseRoles, loadingRoles, errorRoles]);

    const structures: Array<{_id: string, name: string}> | undefined = useMemo(() => {
        if (!responseStructures || loadingStructures || errorStructures) { return undefined; }
        return responseStructures;
    }, [responseStructures, loadingStructures, errorStructures]);

    const handleChangePage = (_event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Box width="100%" overflow="auto" display="inline-flex" flexDirection="column" gap={0}>
            <ContentHeader
                title="Roles"
                counter={roles ? roles.length : 0}
                buttonText="Nuevo rol"
                onClick={() => { setOpen(true); }}
            />

            <Box sx={{ p: '2rem' }}>
                { !loadingRoles && !errorRoles && (
                    <TableContainer sx={{ width: '100%', overflowX: 'scroll' }}>
                        <StyledTable cellSpacing={0} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell />
                                    { structures && structures.map((str) => (
                                        <TableCell key={`${str._id}-header`}>
                                            <strong>
                                                {str.name}
                                            </strong>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { roles && structures && roles.map((role: IRole) => (
                                    <Row
                                        key={`${role._id}-row`}
                                        roleId={role._id!}
                                        structures={structures}
                                        refetch={fetchData}
                                    />
                                )) }

                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                )}
                {roles && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={roles ? roles.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Box>
            { open && (
                <New
                    open={open}
                    onClose={() => { setOpen(false); }}
                    onSubmit={() => { fetchData(); setOpen(false); }}
                    title="Editar rol"
                />
            )}
        </Box>
    );
};

export default Table;
