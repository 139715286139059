import React, {
    useEffect, useState, useCallback, startTransition
} from 'react';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import useAxios from '../../../../../hooks/useAxios';

import { MarginBox, ElementBox, Indicator } from './styles';

import {
    IRelation, IBaseBBDD, IBBDD, CardinalitiesType
} from '../../../../../types/bbdd';

type selectOptionType = {value: CardinalitiesType, text: string};

const cardinalityOptions: Array<selectOptionType> = [
    {
        value: 'OneToOne',
        text: 'Uno a uno'
    },
    {
        value: 'ManyToOne',
        text: 'Muchos a uno'
    },
    {
        value: 'OneToMany',
        text: 'Uno a muchos'
    }
];

type relationCandidateType = { _id: string, name: string };

export interface IProps {
    relation: IRelation
    readonly structure: IBaseBBDD | IBBDD;
    onChange: (relation: IRelation) => void;
    onRemove: () => void;
}

const Relation: React.FC<IProps> = ({
    structure, relation, onChange, onRemove
}) => {
    const [apiNameInvalid, setApiNameInvalid] = useState<boolean>(true);
    const [
        relationCandidates,
        setRelationCandidates
    ] = useState<Array<relationCandidateType>>([]);
    const [selectValue, setSelectValue] = useState<string>(relation.relatedWith);

    const depends: Array<string> = structure.relations.map((
        rel: IRelation
    ) => (rel.relatedWith));

    const generateExcludeList = useCallback((): string => {
        let excludeString: string = '';
        const excludeMap = structure.relations.map((value: IRelation) => (
            value.relatedWith
        ))
            .filter((el: string) => el && el !== selectValue)
            .join('&exclude[]=');
        if (excludeMap) {
            excludeString = `exclude[]=${excludeMap}`;
        }
        if ('_id' in structure) {
            excludeString = `exclude[]=${structure._id}${excludeString ? `&${excludeString}` : ''}`;
        }

        return excludeString;
    }, [JSON.stringify(depends), structure.relations.length]);

    const { response, loading, refetch } = useAxios({
        url: `/api/structures?${generateExcludeList()}`,
        method: 'get'
    });

    const handleChangeRelatedWith = (e: SelectChangeEvent) => {
        setSelectValue(e.target.value);
        onChange({ ...relation, relatedWith: e.target.value });
    };

    const handleChangeApiName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            onChange({ ...relation, apiName: e.target.value });
        });
    };

    const handleCardinalityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        startTransition(() => {
            onChange({ ...relation, cardinality: e.target.value });
        });
    };

    const getRelationCandidates = async () => {
        setRelationCandidates(response || []);
    };

    const maxOrder = structure
        ? structure.fields.length + structure.relations.length
        : 1;

    useEffect(() => {
        refetch();
    }, [JSON.stringify(depends)]);

    useEffect(() => {
        getRelationCandidates();
    }, [response]);

    useEffect(() => {
        if (!relation.apiName || !relation.apiName.match('^[a-z]+$')) {
            setApiNameInvalid(true);
        } else {
            setApiNameInvalid(false);
        }
    }, [relation.apiName]);

    return (

        <ElementBox>
            <Indicator />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <MarginBox>
                        <TextField
                            variant="outlined"
                            name="apiName"
                            label="Nombre en API"
                            fullWidth
                            error={apiNameInvalid}
                            defaultValue={relation.apiName}
                            onChange={(e) => { handleChangeApiName(e); }}
                        />
                    </MarginBox>
                    <FormControl fullWidth>
                        <InputLabel id={`selector-relation-${relation._id}`}>
                            Relacionada
                        </InputLabel>
                        <Select
                            labelId={`selector-relation-${relation._id}`}
                            value={relationCandidates.length ? relation.relatedWith : ''}
                            label="Tipo"
                            disabled={!relation.new}
                            onChange={(e) => { handleChangeRelatedWith(e); }}
                        >
                            { !loading && relationCandidates.map((
                                option: relationCandidateType
                            ) => (
                                <MenuItem
                                    key={option._id}
                                    value={option._id}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="selector-cardinality">Cardinalidad</FormLabel>
                        <RadioGroup
                            aria-labelledby="selector-cardinality"
                            value={relation.cardinality
                                ? relation.cardinality
                                : cardinalityOptions[0].value}
                            row
                            onChange={handleCardinalityChange}
                        >
                            { cardinalityOptions.map((option: selectOptionType) => (
                                <FormControlLabel
                                    key={option.value}
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.text}
                                />

                            ))}
                        </RadioGroup>
                    </FormControl>

                </Grid>
                <Grid item xs={12}>
                    <FormLabel component="legend"> Opciones de relación </FormLabel>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={relation.shown}
                                            onChange={() => {
                                                onChange({
                                                    ...relation,
                                                    shown: !relation.shown
                                                });
                                            }}
                                        />
                                    )}
                                    label="Mostrar"
                                />
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={relation.blockThisSide}
                                            onChange={() => {
                                                onChange({
                                                    ...relation,
                                                    blockThisSide: !relation.blockThisSide
                                                });
                                            }}
                                        />
                                    )}
                                    label="Bloqueado"
                                />
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={relation.filter}
                                            onChange={() => {
                                                onChange({
                                                    ...relation,
                                                    filter: !relation.filter
                                                });
                                            }}
                                        />
                                    )}
                                    label="Habilitar filtro"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="selector-order-relation"> Orden </InputLabel>
                                <Select
                                    labelId="selector-order-relation"
                                    value={relation.order}
                                    label="Tipo"
                                    onChange={(e: SelectChangeEvent<number>) => {
                                        onChange({
                                            ...relation,
                                            order: parseInt(e.target.value.toString(), 10)
                                        });
                                    }}
                                >
                                    { [...Array(maxOrder)].map((_, i: number) => (
                                        <MenuItem
                                            key={`option ${(i + 1)}`}
                                            value={i}
                                        >
                                            {(i + 1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        onClick={onRemove}
                    >
                        Eliminar relación
                    </Button>
                </Grid>
            </Grid>

        </ElementBox>
    );
};

export default Relation;
