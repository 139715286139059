import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import SQLSource from './SQLSource';

export interface IProps {
    onLoad?: (data: Array<Record<string, any>>) => void;
}

const Source: React.FC<IProps> = ({ onLoad }) => {
    const [selectedSource, setSelectedSource] = useState<string>('');

    return (
        <Box display="flex" flexDirection="column" gap="2rem">
            <Typography variant="h4" textAlign="center">
                Fuente
            </Typography>

            <FormControl fullWidth>
                <InputLabel id="source-select-label">Fuente</InputLabel>
                <Select
                    labelId="source-select-label"
                    label="Fuente"
                    value={selectedSource}
                    onChange={(event) => setSelectedSource(event.target.value as string)}
                    id="source-selector"
                >
                    <MenuItem value="">
                        <em>Seleccione una fuente</em>
                    </MenuItem>
                    {/*
                    <MenuItem
                        value="csv"
                    >
                        CSV
                    </MenuItem>
                    <MenuItem
                        value="json"
                    >
                        JSON
                    </MenuItem>
                    */}
                    <MenuItem
                        value="mysql"
                    >
                        MySQL
                    </MenuItem>
                    <MenuItem
                        value="excel"
                    >
                        Excel
                    </MenuItem>
                </Select>
            </FormControl>
            { selectedSource === 'mysql' && (
                <SQLSource dialect="mysql" onLoad={onLoad} />
            )}
        </Box>
    );
};

export default Source;
