import React, { useContext } from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

import useAxiosFunction from '../../../hooks/useAxiosFunction';

import axios from '../../../utils/axiosIntercepter';
import { UserContext } from '../../../App';

import {
    StyledTextBox, StyledDocContainer, StyledActionBox, StyledDateBox, ResponsiveDocs
} from './styles';

export interface IProps {
    docId: string;
    docName: string;
    onUpdate: () => void;
    createdAt?: Date;
    // updatedAt?: Date;
}

const Doc: React.FC<IProps> = ({
    docId, docName, onUpdate, createdAt
}) => {
    const { platform } = useContext(UserContext);
    const handleDownloadFile = async () => {
        if (!platform) { return; }
        try {
            const res = await axios({
                method: 'get',
                url: `/api/platforms/${platform}/files/${docId}?download=true`,
                responseType: 'blob',
                headers: {}
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', docName || 'file');
            document.body.appendChild(link);
            link.click();
        } catch {
            console.warn('Error downloading');
        }
    };

    const { axiosFetch: removeAxiosFunction } = useAxiosFunction();
    const handleRemoveFile = async () => {
        if (docId || !platform) {
            await removeAxiosFunction({
                url: `/api/platforms/${platform}/files/${docId}`,
                method: 'delete'
            });

            onUpdate();
        }
    };
    return (
        <ResponsiveDocs>
            <StyledDocContainer>
                <StyledTextBox>
                    <InsertDriveFileIcon color="primary" />
                    <strong>
                        <span style={{
                            textOverflow: 'ellipsis',
                            maxWidth: '200px'
                        }}
                        >
                            {docName}
                        </span>
                    </strong>
                </StyledTextBox>
                <StyledActionBox>
                    <StyledDateBox sx={{ display: { xs: 'none', md: 'inline-block' }, marginTop: '1em', marginRight: '0.6em' }}>
                        <strong style={{}}>
                            {`${createdAt?.toString().slice(8, 10)}/${createdAt?.toString().slice(5, 7)}/${createdAt?.toString().slice(0, 4)}`}
                        </strong>
                    </StyledDateBox>
                    <IconButton component="label" onClick={handleRemoveFile}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton component="label" onClick={handleDownloadFile}>
                        <BrowserUpdatedIcon />
                    </IconButton>

                </StyledActionBox>
            </StyledDocContainer>
        </ResponsiveDocs>
    );
};

export default Doc;
