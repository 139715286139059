import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo
} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import MoreVert from '@mui/icons-material/MoreVert';
import Close from '@mui/icons-material/Close';
import Info from '@mui/icons-material/Info';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import axiosInstance from '../../../utils/axiosIntercepter';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import { UserContext } from '../../../App';

interface ISubmenu {
    name: string;
    structures: Array<string>;
    tooltip?: string;
}

interface IUnordered {
    id: string;
    name?: string;
}

interface ISelectUnorder extends IUnordered {
    index: number;
}

export interface IProps {
    sidebarUpdater: () => void;
}

const Submenus: React.FC<IProps> = ({ sidebarUpdater }: IProps) => {
    const [newSubmenu, setNewSubmenu] = useState<string>('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectUnorder, setSelectUnorder] = useState<ISelectUnorder | undefined>();
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [tooltipEditName, setTooltipEditName] = useState<string | undefined>();
    const [tooltipInput, setTooltipInput] = useState<string>('');

    const { platform } = useContext(UserContext);

    const {
        response: submenusResponse,
        loading: submenusLoading,
        error: submenusError,
        axiosFetch: getSubmenus
    } = useAxiosFunction();

    const {
        response: structuresResponse,
        loading: structuresLoading,
        error: structuresError,
        axiosFetch: getStructures
    } = useAxiosFunction();

    const submenus: Array<ISubmenu> | undefined = useMemo(() => {
        if (!submenusResponse || submenusLoading || submenusError) { return undefined; }
        return submenusResponse;
    }, [submenusResponse, submenusLoading, submenusError]);

    const structures: Array<{ _id: string, name: string}> | undefined = useMemo(() => {
        if (!structuresResponse || structuresLoading || structuresError) { return undefined; }
        return structuresResponse;
    }, [structuresResponse, structuresLoading, structuresError]);

    const unsortedStructures: Array<{ _id: string, name: string}> | undefined = useMemo(() => {
        if (!submenus || !structures) { return undefined; }

        const sortedStructures: Array<string> = [];
        submenus.forEach((submenu: ISubmenu) => {
            structures.forEach((structure: { _id: string, name: string}) => {
                if (submenu.structures.includes(structure._id)) {
                    sortedStructures.push(structure._id);
                }
            });
        });

        return structures.filter((structure: { _id: string, name: string}) => (
            !sortedStructures.includes(structure._id)
        ));
    }, [submenus, structures]);

    const fetchData = useCallback(() => {
        if (!platform || !getSubmenus || !getStructures) { return; }
        getSubmenus({
            url: `/api/platforms/${platform}/preferences/submenus`,
            method: 'get'
        });
        getStructures({ url: '/api/structures?q=_id&q=name', method: 'get' });
        sidebarUpdater();
    }, [platform, getSubmenus, getStructures]);

    const createSubmenu = useCallback(async () => {
        if (!platform) { return; }
        await axiosInstance.post(
            `/api/platforms/${platform}/preferences/submenus`,
            {
                data: {
                    name: newSubmenu
                }
            }
        );
        setNewSubmenu('');
        fetchData();
    }, [platform, newSubmenu, fetchData]);

    const removeSubmenu = useCallback(async (name: string) => {
        if (!platform) { return; }
        await axiosInstance.delete(
            `/api/platforms/${platform}/preferences/submenus/${name}`
        );
        fetchData();
    }, [platform, fetchData]);

    const handleTextInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setNewSubmenu(() => event.target.value);
    };

    const handleDialogOpen = (id: string, index: number) => {
        setSelectUnorder(() => ({ id, index }));
        setDialogOpen(true);
    };

    const getStructureName = (id: string): string => {
        if (!structures) { return ''; }
        const structure = structures.find((value: { _id: string, name: string}) => (
            value._id === id
        ));
        if (structure && structure.name) {
            return structure.name;
        }
        return '';
    };

    const handleClose = () => {
        setSelectUnorder(() => (undefined));
        setDialogOpen(false);
    };

    const handleTooltipClose = () => {
        setTooltipInput('');
        setTooltipOpen(false);
        setTooltipEditName(undefined);
    };

    const handleOpenTooltip = (submenu: ISubmenu) => {
        setTooltipEditName(submenu.name);
        setTooltipInput(submenu.tooltip || '');
        setTooltipOpen(true);
    };

    const handlePersistTooltip = async () => {
        if (tooltipEditName) {
            await axiosInstance.put(
                `/api/platforms/${platform}/preferences/submenus/${tooltipEditName}`,
                { data: { tooltip: tooltipInput } }
            );
        }
        handleTooltipClose();
    };

    const handleSelectSubmenu = async (submenu: ISubmenu) => {
        if (selectUnorder) {
            submenu.structures.push(selectUnorder.id);
        }

        await axiosInstance.put(
            `/api/platforms/${platform}/preferences/submenus/${submenu.name}`,
            { data: { structures: submenu.structures } }
        );

        handleClose();
        fetchData();
    };

    const handleSetUnorder = async (submenu: ISubmenu, structureId: string) => {
        if (!submenu.structures.includes(structureId)) { return; }

        const submenuStructures = submenu.structures.filter((id: string) => (id !== structureId));

        await axiosInstance.put(
            `/api/platforms/${platform}/preferences/submenus/${submenu.name}`,
            { data: { structures: submenuStructures } }
        );

        fetchData();
    };

    useEffect(() => { fetchData(); }, [fetchData]);

    return (

        <Box sx={{ width: '100%' }}>
            <Paper elevation={4} sx={{ padding: '1rem 0', margin: '0.5rem 0' }}>
                <Typography variant="h4" align="center">
                    Submenús
                </Typography>
            </Paper>

            <Grid container spacing={3} direction="row">
                <Grid
                    item
                    xs={12}
                    lg={3}
                >
                    <Paper
                        elevation={2}
                        sx={{
                            padding: '1rem',
                            position: 'relative'
                        }}
                    >
                        <Paper elevation={1}>
                            <Typography
                                variant="h5"
                                align="center"
                                gutterBottom
                            >
                                Secciones
                            </Typography>
                        </Paper>
                        <List>
                            { submenus && submenus.map((submenu: ISubmenu) => (
                                <ListItem
                                    key={submenu.name}
                                    secondaryAction={(
                                        <>
                                            <IconButton
                                                aria-label="remove"
                                                onClick={() => { removeSubmenu(submenu.name); }}
                                            >
                                                <Delete />
                                            </IconButton>
                                            <IconButton
                                                aria-label="info"
                                                onClick={() => {
                                                    handleOpenTooltip(submenu);
                                                }}
                                            >
                                                <Info />
                                            </IconButton>
                                        </>

                                    )}
                                >
                                    <Typography sx={{
                                        width: '80%',
                                        overflowWrap: 'break-word'
                                    }}
                                    >
                                        {submenu.name}
                                    </Typography>

                                </ListItem>
                            ))}
                        </List>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nuevo"
                            sx={{
                                margin: '0.5rem 0'
                            }}
                            value={newSubmenu}
                            onChange={handleTextInputChange}
                        />
                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={createSubmenu}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={9}
                >
                    <Paper elevation={2} sx={{ padding: '1rem', height: '100%' }}>
                        <Paper elevation={1}>
                            <Typography
                                variant="h5"
                                align="center"
                                gutterBottom
                            >
                                Orden
                            </Typography>
                        </Paper>

                        { submenus && submenus.map((submenu: ISubmenu, indexMenu: number) => (
                            <Box key={`Submenu-${submenu.name}-${indexMenu.toString()}`}>
                                { submenu.structures.length > 0
                                    && (

                                        <Paper
                                            sx={{
                                                padding: '1rem',
                                                marginBottom: '1rem'
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                align="left"
                                                gutterBottom
                                            >
                                                {submenu.name}
                                            </Typography>
                                            <List>
                                                { submenu.structures.map((
                                                    id: string, index: number
                                                ) => (
                                                    <ListItem
                                                        key={`SubMenuItem-${index.toString}${indexMenu.toString}${id}`}
                                                        disablePadding
                                                        secondaryAction={(
                                                            <IconButton
                                                                onClick={
                                                                    () => {
                                                                        handleSetUnorder(
                                                                            submenu,
                                                                            id
                                                                        );
                                                                    }
                                                                }
                                                            >
                                                                <Close
                                                                    aria-label="remove"
                                                                />
                                                            </IconButton>
                                                        )}
                                                    >
                                                        <ListItemText sx={{
                                                            paddingLeft: '1rem'
                                                        }}
                                                        >
                                                            { getStructureName(id) }
                                                        </ListItemText>
                                                    </ListItem>
                                                )) }
                                            </List>
                                        </Paper>
                                    ) }
                            </Box>
                        )) }
                        <List>
                            { unsortedStructures && unsortedStructures.map((
                                item: { _id: string, name: string}, index: number
                            ) => (
                                <Paper
                                    sx={{
                                        marginY: '0.2rem',
                                        width: '100%'
                                    }}
                                    key={`unorder-${item._id}`}
                                >
                                    <ListItem
                                        sx={{ width: '100%' }}
                                        secondaryAction={(
                                            <IconButton
                                                aria-label="remove"
                                                onClick={
                                                    () => {
                                                        handleDialogOpen(item._id, index);
                                                    }
                                                }
                                            >
                                                <MoreVert />
                                            </IconButton>
                                        )}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '1.1rem',
                                                width: '80%'

                                            }}
                                            gutterBottom
                                        >
                                            {item.name}
                                        </Typography>
                                    </ListItem>
                                </Paper>
                            )) }
                        </List>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
            >
                <DialogTitle> Menú </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Seleccione el submenú al que estará asignada esta base de información
                    </DialogContentText>
                    <List>
                        { submenus && submenus.map((submenu: ISubmenu) => (
                            <ListItem
                                key={`selector-${submenu.name}`}
                                disablePadding
                            >
                                <ListItemButton onClick={
                                    () => {
                                        handleSelectSubmenu(submenu);
                                    }
                                }
                                >
                                    <ListItemText>
                                        { submenu.name }
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )) }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tooltipOpen}
                onClose={handleTooltipClose}
            >
                <DialogTitle> Tooltip </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Introduzca texto de ayuda
                    </DialogContentText>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Tooltip"
                        sx={{
                            margin: '0.5rem 0'
                        }}
                        value={tooltipInput}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                        ) => setTooltipInput(() => e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePersistTooltip}>Guardar</Button>
                    <Button onClick={handleTooltipClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
export default Submenus;
