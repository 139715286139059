import React, {
    useState,
    useCallback,
    startTransition,
    useContext
} from 'react';

import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Modal, { IProps as ModalProps } from '../../sideModal';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import { UserContext } from '../../../../App';

import StyledContainer from './styles';

export interface IProps extends Omit<ModalProps, 'onSubmit'> {
    roleId: string;
    initialName: string;
    initialAdmin: boolean;
    onSubmit: () => void;
}

const Edit: React.FC<IProps> = ({
    roleId, initialName, initialAdmin, onSubmit, ...rest
}) => {
    const [name, setName] = useState<string>(initialName);
    const [isPlatformAdmin, setIsPlatformAdmin] = useState<boolean>(initialAdmin);

    const { platform } = useContext(UserContext);

    const { axiosFetch } = useAxiosFunction();

    const handleSubmit = useCallback(async () => {
        await axiosFetch({
            url: `/api/platforms/${platform}/roles/${roleId}`,
            method: 'put',
            data: {
                name,
                isPlatformAdmin
            }
        });
        onSubmit();
    }, [onSubmit, axiosFetch, name, isPlatformAdmin, platform, roleId]);

    const handleChangeName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setName(() => e.target.value);
        });
    };

    const handleChangeIsAdmin = () => {
        startTransition(() => {
            setIsPlatformAdmin(!isPlatformAdmin);
        });
    };

    return (
        <Modal
            onSubmit={handleSubmit}
            {...rest}
        >
            <StyledContainer>
                <TextField
                    variant="outlined"
                    name="name"
                    label="Nombre"
                    fullWidth
                    defaultValue={name}
                    onChange={(e) => { handleChangeName(e); }}
                />
            </StyledContainer>
            <FormGroup>
                <FormControlLabel
                    control={(
                        <Switch
                            name="isAdmin"
                            defaultChecked={initialAdmin}
                            onChange={() => handleChangeIsAdmin()}
                        />
                    )}
                    label="Administrador"
                />
            </FormGroup>
        </Modal>
    );
};

export default Edit;
