import React, {
    useState,
    useEffect,
    useMemo,
    useCallback
} from 'react';
import {
    Route,
    Routes,
    useLocation,
    useNavigate
} from 'react-router-dom';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Box from '@mui/material/Box';

import axiosInstance from './utils/axiosIntercepter';
import './styles/base/base.scss';
import 'font-awesome/css/font-awesome.min.css';

import DesktopSidebar from './components/layout/sidebar/desktop';
import MobileSidebar from './components/layout/sidebar/mobile';
import Content from './components/layout/content';
import Topbar from './components/layout/topbar';

import { ISubmenu, IRequirements } from './components/layout/sidebar/sidebar';
import Users from './pages/users/Users';
import BBDD from './pages/bbdd';
import Config from './pages/config';
import Data from './pages/data';
import Docs from './pages/docs';
import Attachements from './pages/bbddDocs';
import Roles from './pages/roles';
import Logs from './pages/logs';
import Imports from './pages/imports';

import useAxiosFunction from './hooks/useAxiosFunction';
import { IBBDD } from './types/bbdd';

interface IUserContext {
    _id?: string;
    role?: string;
    platform?: string;
    username?: string;
    rootFolder?: string | null;
}

export const UserContext = React.createContext<IUserContext>({
});

const App: React.FC<{}> = () => {
    const [isColapsed, setIsColapsed] = useState(false);
    const [credentials, setCredentials] = useState<IUserContext>({});
    const [visualizationLink, setVisualizationLink] = useState<string | undefined>();

    const navigator = useNavigate();

    const setCredentialsContext = (
        _id?: string,
        role?: string,
        platform?: string,
        username?: string,
        rootFolder?: string | null
    ) => {
        setCredentials(() => ({
            role, _id, platform, username, rootFolder
        }));
    };

    const {
        response: structuresResponse,
        error: structuresError,
        loading: structuresLoading,
        axiosFetch: getStructures
    } = useAxiosFunction();

    const {
        response: submenusResponse,
        error: submenusError,
        loading: submenusLoading,
        axiosFetch: getSubmenus
    } = useAxiosFunction();

    const structures: Array<IBBDD> | undefined = useMemo(() => {
        if (!structuresResponse || structuresLoading || structuresError) {
            return undefined;
        }
        return structuresResponse;
    }, [structuresResponse, structuresLoading, structuresError]);

    const submenus: Array<{
        name: string,
        tooltip?: string,
        structures: Array<string>
    }> | undefined = useMemo(() => {
        if (!submenusResponse || submenusLoading || submenusError) {
            return undefined;
        }
        return submenusResponse;
    }, [submenusResponse, submenusLoading, submenusError]);

    const parsedSubmenus = useMemo(() => {
        if (!submenus || !structures) {
            return undefined;
        }

        const parsedSubmenusArray: Array<ISubmenu> = submenus
            .map((submenu) => {
                const structuresArray: Array<IRequirements> = submenu.structures
                    .map((structureId) => {
                        const foundStructure = structures.find((
                            structure
                        ) => structure._id === structureId);
                        if (!foundStructure) {
                            return undefined;
                        }
                        return {
                            title: foundStructure.name,
                            route: foundStructure._id,
                            iconName: ''
                        };
                    })
                    .filter((structure) => structure !== undefined) as Array<IRequirements>;

                return ({
                    name: submenu.name || '',
                    tooltip: submenu.tooltip || '',
                    structures: structuresArray
                });
            })
            .filter((submenu) => submenu.structures.length > 0);

        const unorderedStructures = structures
            .filter((structure) => {
                const isPresentInSubmenu = submenus.find((
                    submenu
                ) => submenu.structures.includes(structure._id));

                if (!isPresentInSubmenu) {
                    return true;
                }
                return false;
            })
            .map((
                structure
            ) => ({ title: structure.name, route: structure._id, iconName: '' }));

        return { submenus: parsedSubmenusArray, unordered: unorderedStructures };
    }, [submenus, structures]);

    const updateData = useCallback(() => {
        if (!credentials || !credentials.platform) { return; }
        if (!getSubmenus || !getStructures) { return; }

        if (credentials.platform) {
            getSubmenus({
                url: `/api/platforms/${credentials.platform}/preferences/submenus`,
                method: 'get'
            });
        }

        getStructures({
            url: '/api/structures',
            method: 'get'
        });
    }, [credentials, credentials.platform, getSubmenus, getStructures]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    const colapseHandler = () => {
        setIsColapsed(!isColapsed);
    };

    const initialCall = useCallback(async () => {
        try {
            const axiosResponse = await axiosInstance.get('/base');
            const {
                _id, role, platform, username, rootFolder
            } = axiosResponse.data;
            setCredentialsContext(_id, role, platform, username, rootFolder);
        } catch (err) {
            console.warn('error');
        }
    }, []);

    const getLink = async () => {
        const response = await axiosInstance.get(`/api/platforms/${credentials.platform}`);
        const { visualizationRoute } = response.data;
        if (visualizationRoute) {
            setVisualizationLink(visualizationRoute);
        } else {
            const bbddQuery = await axiosInstance.get('/api/base');
            if (bbddQuery.status === 200 && bbddQuery.data && Array.isArray(bbddQuery.data)) {
                const arrayData: Array<{ _id: string }> = bbddQuery.data;
                if (arrayData.length > 0 && arrayData[0]._id) {
                    const id = arrayData[0]._id;
                    navigator(id);
                }
            }
        }
    };

    useEffect(() => {
        initialCall();
    }, [initialCall]);

    useEffect(() => {
        if (credentials.platform) {
            getLink();
        }
    }, [credentials]);

    return (
        <UserContext.Provider value={credentials}>
            <Topbar
                colapseHandler={colapseHandler}
                username={credentials.username || ''}
            />

            <Box sx={{ display: { md: 'none' } }}>
                <MobileSidebar
                    isOpen={isColapsed}
                    colapseHandler={colapseHandler}
                    items={parsedSubmenus}
                />
            </Box>
            <LocalizationProvider dateAdapter={AdapterLuxon}>

                <div className="App full-view">
                    {parsedSubmenus
                        && (
                            <>
                                <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
                                    <DesktopSidebar items={parsedSubmenus} />
                                </Box>
                            </>
                        )}

                    <Routes>
                        <Route
                            index
                            element={(
                                <iframe
                                    src={visualizationLink || ''}
                                    frameBorder="0"
                                    title="view"
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            )}
                        />
                        <Route
                            path="/BBDD"
                            element={(
                                <BBDD updateSidebar={updateData} />
                            )}
                        />
                        <Route
                            path="/roles"
                            element={(
                                <Roles />
                            )}
                        />
                        <Route
                            path="/documentacion"
                            element={(
                                <Content title="Documentación">
                                    <Docs />
                                </Content>
                            )}
                        />
                        <Route
                            path="/documentacion/:folderId"
                            element={(
                                <Content title="Documentación">
                                    <Docs />
                                </Content>
                            )}
                        />
                        <Route
                            path="/documentacion/structure/:structureId"
                            element={(
                                <Content title="Documentación">
                                    <Attachements />
                                </Content>
                            )}
                        />
                        <Route
                            path="/config"
                            element={(
                                <Content title="Configuración">
                                    <Config sidebarUpdater={updateData} />
                                </Content>
                            )}
                        />
                        <Route
                            path="/users"
                            element={(
                                <Users />
                            )}
                        />
                        <Route
                            path="/logs"
                            element={(
                                <Logs />
                            )}
                        />
                        <Route
                            path="/imports"
                            element={(
                                <Imports />
                            )}
                        />
                        <Route
                            path="/:id"
                            element={(
                                <Data key={useLocation().pathname} />
                            )}
                        />
                    </Routes>
                </div>
            </LocalizationProvider>
        </UserContext.Provider>
    );
};

export default App;
