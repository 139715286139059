import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import EditForm from '../../../components/molecules/platform/form/Edit';

import axiosInstance from '../../../utils/axiosIntercepter';

import * as S from './styles';

interface IPlatform {
    _id: string;
    name: string;
    description: string;
    subtitle: string;
    isHidden: boolean;
    isLocked: boolean;
    isFilteredByClassifier: boolean;
    [x: string]: any;
}

interface ICardProps {
    initialPlatform: IPlatform;
    editable: boolean;
    updater: () => void;
}

const PlatformCard: React.FC<ICardProps> = ({
    initialPlatform, updater, editable
}: ICardProps) => {
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const requestAccess = useCallback(async () => {
        if (!initialPlatform || !navigate) { return; }
        try {
            await axiosInstance.get(`/api/platforms/${initialPlatform._id}/access`);
            console.warn('navigate');
            navigate('/');
        } catch {
            console.warn('Error navigating to home');
        }
    }, [initialPlatform, navigate]);

    if (initialPlatform) {
        return (
            <S.Card>
                { editable && (
                    <IconButton sx={{ top: '10px', right: '15px', position: 'absolute' }} onClick={() => setPopupOpen(true)}>
                        <EditIcon />
                    </IconButton>
                )}

                <S.CardSubtitle>{ initialPlatform.subtitle }</S.CardSubtitle>
                <S.CardHeader>{ initialPlatform.name }</S.CardHeader>
                <S.CardContent>{ initialPlatform.description }</S.CardContent>
                <S.CardActions>
                    <Button variant="contained" onClick={requestAccess} sx={{ width: '100%', padding: '10px 50px' }}>
                        <IconButton sx={{ color: 'white', width: '10px' }}>
                            <ArrowForwardIcon sx={{ color: 'white', right: '20px', position: 'absolute' }} />
                        </IconButton>
                        Acceder
                    </Button>
                </S.CardActions>

                {popupOpen && (
                    <EditForm
                        id={initialPlatform._id}
                        onClose={() => { setPopupOpen(false); }}
                        onSubmit={() => { updater(); setPopupOpen(false); }}
                        open={popupOpen}
                        title="Editar Plataforma"
                    />
                )}
            </S.Card>
        );
    }
    return (<></>);
};

export default PlatformCard;
