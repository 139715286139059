import React, {
    useState,
    useCallback,
    useMemo
} from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import { IProps as SourceProps } from '.';

export interface IProps extends SourceProps {
    dialect: 'mysql';
}

const SQLSource: React.FC<IProps> = ({ dialect, onLoad }) => {
    const [connectionString, setConnectionString] = useState<string>('');
    const [query, setQuery] = useState<string>('');

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);

    const {
        response,
        loading,
        error,
        axiosFetch: getData
    } = useAxiosFunction();

    const data: Array<Record<string, any>> | undefined = useMemo(() => {
        if (!response || loading || error) { return undefined; }
        if (!response.result || !Array.isArray(response.result)) { return undefined; }
        onLoad?.(response.result);
        return response.result;
    }, [response]);

    const columns: Array<string> | undefined = useMemo(() => {
        if (!data) { return undefined; }
        if (data.length === 0) { return undefined; }
        return Object.keys(data[0]);
    }, [data]);

    const rows: Array<Array<any>> | undefined = useMemo(() => {
        if (!data) { return undefined; }
        if (data.length === 0) { return undefined; }
        return data.map((row) => Object.values(row));
    }, [data]);

    const handleFetchData = useCallback(async () => {
        getData({
            method: 'post',
            url: `/api/helpers/external/${dialect}`,
            data: {
                connectionUrl: connectionString,
                query
            }
        });
    }, [connectionString, query]);

    return (
        <Box display="flex" flexDirection="column" gap="2rem" alignItems="center">

            <TextField
                fullWidth
                label="URL de conexión"
                value={connectionString}
                onChange={(event) => setConnectionString(event.target.value)}
                id="connection-string"
            />

            <TextField
                fullWidth
                label="Consulta"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                id="query"
            />

            <Button
                variant="contained"
                onClick={handleFetchData}
            >
                Ejecutar consulta
            </Button>

            {columns && rows && (
                <>
                    <TableContainer component={Paper} elevation={5}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column}>
                                            <strong>{column}</strong>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                        <TableRow
                                            key={`row-${(index + page * rowsPerPage).toString()}`}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {row.map((cell) => (
                                                <TableCell key={cell}>
                                                    {cell}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(_event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                    />
                </>

            )}

        </Box>
    );
};

export default SQLSource;
