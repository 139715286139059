import React, {
    useEffect,
    useState,
    useMemo,
    useCallback
} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Person from '@mui/icons-material/Person';

import Topbar from '../../../components/layout/topbar';
import StorageIndicator from '../../../components/molecules/docs/StorageIndicator';

import axios from '../../../utils/axiosIntercepter';
import useAxiosFunction from '../../../hooks/useAxiosFunction';

const User: React.FC = () => {
    const [usernameInput, setUsernameInput] = useState<string>('');
    const [emailInput, setEmailInput] = useState<string>('');

    const {
        response: userResponse,
        error: userError,
        loading: userLoading,
        axiosFetch: getUser
    } = useAxiosFunction();

    const getUserData = useCallback(async () => {
        getUser({
            url: '/api/state/user',
            method: 'get'
        });
    }, [getUser]);

    const license = useMemo(() => {
        if (!userResponse || userLoading || userError) { return ''; }
        if (!userResponse._id) { return ''; }

        return userResponse._id;
    }, [userResponse, userError, userLoading]);

    const fetchUsername = useMemo(() => {
        if (!userResponse || userLoading || userError) { return []; }

        return userResponse.username || '';
    }, [userResponse, userError, userLoading]);

    const isValidEmail = useMemo(() => {
        const regex = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');
        return regex.test(emailInput);
    }, [emailInput]);

    const error = useMemo(() => {
        if (usernameInput.length < 8 || usernameInput.length > 20) {
            return 'El nombre de usuario debe tener entre 8 y 20 caracteres';
        }

        if (!isValidEmail) {
            return 'El email no es válido';
        }

        return '';
    }, [usernameInput, isValidEmail]);

    const handleSave = useCallback(async (username: string, email: string) => {
        try {
            await axios.put('/api/auth/', {
                data: {
                    username,
                    email
                }
            });
        } catch (e) {
            console.warn(e);
        } finally {
            getUserData();
        }
    }, [axios, getUserData]);

    useEffect(() => {
        if (!userResponse || userLoading || userError) { return; }
        if (!userResponse.username || !userResponse.email) { return; }

        setUsernameInput(userResponse.username);
        setEmailInput(userResponse.email);
    }, [userResponse, userError, userLoading]);

    useEffect(() => {
        if (getUserData) { getUserData(); }
    }, [getUserData]);

    return (
        <Box display="flex" flexDirection="column">
            <Topbar username={fetchUsername} />
            <Box
                maxWidth="50rem"
                width="100%"
                display="flex"
                flexDirection="column"
                gap="1rem"
                margin="auto"
                marginTop="5rem"
                alignItems="center"
                padding="1rem"
            >
                <Avatar sx={{
                    width: '10rem',
                    height: '10rem',
                    bgcolor: 'lightgray'
                }}
                >
                    <Person sx={{ fontSize: '8rem' }} />
                </Avatar>
                <Typography
                    variant="h6"
                    color="gray"
                    marginTop="2rem"
                    whiteSpace="nowrap"
                >
                    Licencia número:
                    {' '}
                    {license}
                </Typography>
                <Typography variant="h6" color="gray">
                    Licencias en uso: 1 de 1
                </Typography>
                <Box
                    width={450}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap="1rem"
                    marginBottom="2rem"
                >
                    <Typography display="inline" variant="h6" color="gray">
                        Almacenamiento
                    </Typography>
                    <Box width={{ md: 300 }}>
                        <StorageIndicator />
                    </Box>
                </Box>
                <TextField
                    id="outlined-basic"
                    label="Username"
                    variant="outlined"
                    value={usernameInput}
                    onChange={(e) => setUsernameInput(e.target.value)}
                    fullWidth
                    error={usernameInput.length < 8 || usernameInput.length > 20}
                />

                <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    fullWidth
                    error={!isValidEmail}
                />

                {error && (
                    <Alert severity="error">
                        {error}
                    </Alert>
                )}
                <Box display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        onClick={() => handleSave(usernameInput, emailInput)}
                        disabled={!!error}
                    >
                        Guardar
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default User;
