import React, {
    useState,
    useCallback,
    useContext,
    useMemo
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { ReactComponent as VisualizationLogo } from '../../../../assets/svgs/visualization.svg';
import { ReactComponent as BasesLogo } from '../../../../assets/svgs/databases.svg';
import { ReactComponent as UtilsLogo } from '../../../../assets/svgs/utils.svg';
import { ReactComponent as ConfigLogo } from '../../../../assets/svgs/config.svg';

import { ISidebarItems, ISubmenu, IRequirements } from '../sidebar';

import { StyledButtonContainer, StyledButton } from './styles';

import { UserContext } from '../../../../App';

type routeType = { route: string, text: string };

const adminConfigRoutes: Array<routeType> = [
    { route: 'users', text: 'Usuarios' },
    { route: 'roles', text: 'Roles' },
    { route: 'bbdd', text: 'BBDD' },
    { route: 'config', text: 'Configuración' },
    { route: 'logs', text: 'Logs' },
    { route: 'imports', text: 'Importar datos' }
];
const userConfigRoutes: Array<routeType> = [
    { route: 'logs', text: 'Logs' },
    { route: 'imports', text: 'Importar datos' }
];

const adminConfigSimpleRoutes: Array<string> = adminConfigRoutes.map((value) => `/${value.route}`);
const userConfigSimpleRoutes: Array<string> = userConfigRoutes.map((value) => `/${value.route}`);

export interface IProps {
    isOpen: boolean;
    colapseHandler: () => void;
    items?: ISidebarItems;
}

const MobileDrawer: React.FC<IProps> = ({ isOpen, colapseHandler, items }: IProps) => {
    const [currentList, setCurrentList] = useState<Array<routeType>>([]);
    const [currentSection, setCurrentSection] = useState<string>('');

    const { pathname } = useLocation();
    const { role, rootFolder } = useContext(UserContext);

    const configRoutes: Array<routeType> = useMemo(() => {
        if (role === 'admin') {
            return adminConfigRoutes;
        }
        return userConfigRoutes;
    }, [role]);

    const configSimpleRoutes: Array<string> = useMemo(() => {
        if (role === 'admin') {
            return adminConfigSimpleRoutes;
        }
        return userConfigSimpleRoutes;
    }, [role]);

    const utilsRoutes: Array<routeType> = useMemo(() => {
        let routes: Array<routeType> = [];
        if (rootFolder === null) {
            routes = [
                { route: 'documentacion', text: 'Documentación' }
            ];
        } else if (typeof rootFolder === 'string') {
            routes = [
                { route: `documentacion/${rootFolder}`, text: 'Documentación' }
            ];
        }
        return routes;
    }, [rootFolder]);

    const utilSimpleRoutes: Array<string> = utilsRoutes.map((value) => `/${value.route}`);

    const getSimpleItemRouteList = () => {
        const routeList: Array<string> = [];
        if (!items) { return []; }

        items.unordered.forEach((value) => {
            routeList.push(`/${value.route}`);
        });
        items.submenus.forEach((submenu) => {
            submenu.structures.forEach((value) => {
                routeList.push(`/${value.route}`);
            });
        });

        return routeList;
    };

    const list = useCallback((routeList: Array<routeType>, section: string) => (
        <Box>
            <List>
                {routeList.map((listItem: routeType, index: number) => (
                    <Link
                        key={`${listItem.text}-${section}-${index.toString()}`}
                        to={`/${listItem.route}`}
                        style={{
                            textDecoration: 'none'
                        }}
                    >
                        <ListItem
                            sx={{
                                maxWidth: 500,
                                minWidth: 200,
                                color: 'white',
                                borderRadius: '0.5rem',
                                backgroundColor: `/${listItem.route}` === pathname ? '#7c7c7caa' : ''
                            }}
                        >
                            {listItem.text}
                        </ListItem>
                    </Link>

                ))}
            </List>
        </Box>
    ), [currentList, pathname]);

    const listBases = () => (
        <Box>

            <List>
                {items && items.submenus.map((
                    submenu: ISubmenu, index: number
                ) => (
                    <Box key={`Submenu-${submenu.name}-${index.toString()}`}>
                        <Box sx={{
                            color: 'white',
                            marginTop: '1.5rem',
                            fontSize: '1.3rem'
                        }}
                        >
                            {submenu.name}

                        </Box>
                        {submenu.structures.map((value: IRequirements) => (
                            <Link
                                key={`${value.route}-Bases-${index.toString()}`}
                                to={`/${value.route}`}
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                <ListItem
                                    sx={{
                                        maxWidth: 500,
                                        minWidth: 200,
                                        color: 'white',
                                        borderRadius: '0.5rem',
                                        backgroundColor: `/${value.route}` === pathname ? '#7c7c7caa' : ''
                                    }}
                                >
                                    {value.title}
                                </ListItem>
                            </Link>

                        ))}
                    </Box>
                ))}
                {items && items.unordered.length > 0 && (
                    <Box sx={{
                        color: 'white',
                        marginTop: '1.5rem',
                        fontSize: '1.3rem'
                    }}
                    >
                        Tablas
                    </Box>

                )}

                {items && items.unordered.map((value: IRequirements, index: number) => (
                    <Link
                        key={`${value.route}-Bases-${index.toString()}`}
                        to={`/${value.route}`}
                        style={{
                            textDecoration: 'none'
                        }}
                    >
                        <ListItem
                            sx={{
                                maxWidth: 500,
                                minWidth: 200,
                                color: 'white',
                                borderRadius: '0.5rem',
                                backgroundColor: `/${value.route}` === pathname ? '#7c7c7caa' : ''
                            }}
                        >
                            {value.title}
                        </ListItem>
                    </Link>

                ))}
            </List>
        </Box>
    );

    const openOnList = (selectedList: Array<routeType>, section: string) => {
        setCurrentList(() => _.cloneDeep(selectedList));
        setCurrentSection(() => section);
    };

    return (

        <Drawer
            transitionDuration={{ enter: 200, exit: 300 }}
            open={isOpen}
            variant="temporary"
            anchor="top"
            onClose={colapseHandler}
            sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: '#505050',
                    padding: '30px',
                    maxHeight: '80%'
                }
            }}
        >
            <StyledButtonContainer>
                <Link
                    to="/"
                    style={{
                        textDecoration: 'none',
                        color: 'black'
                    }}
                >
                    <StyledButton
                        selected={pathname === '/'}
                        onClick={() => {
                            colapseHandler();
                            openOnList([], 'Visualización');
                        }}
                    >
                        <VisualizationLogo />
                    </StyledButton>
                </Link>
                <StyledButton
                    selected={getSimpleItemRouteList().includes(pathname)}
                    onClick={() => openOnList([], 'Bases')}
                >
                    <BasesLogo />
                </StyledButton>

                <>
                    <StyledButton
                        selected={utilSimpleRoutes.includes(pathname)}
                        onClick={() => openOnList(utilsRoutes, 'Utilidades')}
                    >
                        <UtilsLogo />
                    </StyledButton>

                    <StyledButton
                        selected={configSimpleRoutes.includes(pathname)}
                        onClick={() => openOnList(configRoutes, 'Configuración')}
                    >
                        <ConfigLogo />
                    </StyledButton>
                </>
            </StyledButtonContainer>
            <Box>
                {currentSection === 'Bases'
                    ? listBases()
                    : list(currentList, currentSection)}
            </Box>
        </Drawer>
    );
};

export default MobileDrawer;
