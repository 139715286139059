import React, { useState, useCallback } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { toast } from 'react-toastify';
import EditForm from '../form/Edit';
import FieldIndicator from './FieldIndicator';
import RelationIndicator from './RelationIndicator';

import toastOptions from '../../../../utils/toastOptions';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';
import useAxios from '../../../../hooks/useAxios';

import { IBBDD, IField, IRelation } from '../../../../types/bbdd';

import { StyledInfoContentRow, StyledSegmentContainer } from './styles';

export interface IProps {
    structure: IBBDD;
    refetch: () => void;
}

const Row: React.FC<IProps> = ({ structure, refetch }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [collapse, setCollapse] = useState<boolean>(false);

    const { axiosFetch: removeAxiosFunction } = useAxiosFunction();
    const { response } = useAxios({
        url: `/api/structures/${structure._id}/entries?count=true`,
        method: 'get'
    });

    const theme = useTheme();
    const matchSmMediaQuery = useMediaQuery(theme.breakpoints.up('md'));

    const handleRemoveStructure = useCallback(async () => {
        try {
            await removeAxiosFunction({
                url: `/api/structures/${structure._id}`,
                method: 'delete'
            });
            toast.success('Dataset removed', toastOptions);
            refetch();
        } catch (err) {
            console.warn(err);
        }
    }, [structure._id, removeAxiosFunction, refetch]);

    const getIdName = (): string => {
        const keyField = structure.fields.find((value) => value.isKeyField);
        if (!keyField) { return ''; }

        return keyField.fieldName;
    };

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        component="span"
                        onClick={handleRemoveStructure}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <IconButton
                        component="span"
                        onClick={() => { setOpen(true); }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        component="span"
                        onClick={() => { setCollapse(!collapse); }}
                    >
                        {collapse ? (<KeyboardArrowUpIcon />) : (<KeyboardArrowDownIcon />)}
                    </IconButton>

                </TableCell>
                <TableCell>
                    {structure.name}
                </TableCell>
                <TableCell>
                    {(response && response.count) || 0}
                </TableCell>
                <TableCell>
                    {getIdName()}
                </TableCell>
                <TableCell>
                    {structure.isLocked ? 'Bloqueado' : 'Activo'}
                </TableCell>
            </TableRow>
            <StyledInfoContentRow collapse={collapse}>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0
                    }}
                    colSpan={matchSmMediaQuery ? 5 : 2}
                >
                    <Collapse in={collapse} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h5" gutterBottom component="div">
                                {structure.name}
                            </Typography>
                            <StyledSegmentContainer>
                                {structure.fields.map((field: IField, index: number) => (
                                    <FieldIndicator
                                        key={`${field._id}-${index.toString()}`}
                                        field={field}
                                    />

                                ))}
                            </StyledSegmentContainer>
                            <StyledSegmentContainer>
                                {structure.relations.map((relation: IRelation, index: number) => (
                                    <RelationIndicator
                                        key={`${relation._id}-${index.toString()}`}
                                        relation={relation}
                                    />

                                ))}
                            </StyledSegmentContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </StyledInfoContentRow>
            { open && (
                <EditForm
                    id={structure._id}
                    open={open}
                    onSubmit={refetch}
                    onClose={() => { setOpen(false); }}
                    title="Editar BBDD"
                />

            ) }
        </>
    );
};

export default Row;
