import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

export interface IProps {
    targetId: string;
    fields: Array<{_id: string, fieldName: string, apiName: string}>;
    data: Array<Record<string, any>>;
    onLoad?: (data: Array<Record<string, any>>) => void;
}

const Validation: React.FC<IProps> = ({
    targetId, fields, data, onLoad
}) => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const parsedData: Array<Record<string, any>> | undefined = useMemo(() => {
        if (!targetId || !fields || !data) { return undefined; }
        if (data.length === 0) { return []; }

        const leanData = data.map((row) => {
            const newRow: Record<string, any> = {};

            fields.forEach((field) => {
                newRow[field.apiName] = row[field.apiName];
            });

            return newRow;
        });

        onLoad?.(leanData);
        return leanData;
    }, [targetId, fields, data]);

    return (
        <Box display="flex" flexDirection="column" gap="2rem">
            <Typography variant="h4" textAlign="center">
                Validación
            </Typography>
            { parsedData && fields && (
                <>
                    <TableContainer component={Paper} elevation={5}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {fields.map((field) => (
                                        <TableCell key={field._id}>
                                            <strong>{field.fieldName}</strong>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parsedData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                        <TableRow
                                            key={`${(index + page * rowsPerPage).toString()}-row}`}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {fields.map((field) => (
                                                <TableCell key={`${field._id}-${index.toString()}-cell`}>
                                                    {row[field.apiName]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 15]}
                        component="div"
                        count={parsedData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(_event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                    />
                </>
            )}
        </Box>
    );
};

export default Validation;
