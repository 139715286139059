import { useState, useEffect } from 'react';

import axios from '../utils/axiosIntercepter';

export interface IParams {
    url: string;
    file: File;
    fileType?: 'att' | 'doc';
    parent?: string;
}

const useUploadFile = () => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [controller, setController] = useState<AbortController>();

    const uploadFunction = async (configObject: IParams) => {
        const { file } = configObject;

        setLoading(true);
        setError('');

        const ctrl = new AbortController();
        setController(() => ctrl);

        try {
            const formData = new FormData();
            formData.append('fileOrigin', configObject.fileType ? configObject.fileType : 'doc');
            if (configObject.parent) {
                formData.append('parent', configObject.parent);
            }
            formData.append('file', file, file.name);
            const result = await axios({
                url: configObject.url,
                data: formData,
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setResponse(result.data || 'Success');
        } catch (err: any) {
            setResponse(undefined);
            if (typeof err.data === 'string') {
                setError(err.data || 'A server error has ocurred');
            } else {
                setError(() => 'A server error has ocurred');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => () => controller && controller.abort(), [controller]);

    return {
        response, error, loading, uploadFunction
    };
};

export default useUploadFile;
