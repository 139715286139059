import styled from 'styled-components';
import IconButton from '../../atoms/control/iconButton';

export const TopbarItem = styled(IconButton)`
    transition: transform 0.5s;
    font-size: 1.8em;
`;

export const ColapseButton = styled(TopbarItem)<{isColapsed: boolean|undefined}>`
    transform: ${(props) => (props.isColapsed
        ? 'rotate(-180deg)'
        : 'rotate(0deg)')}
`;

export const Topbar = styled.div`
    height: 8vh;
    display: flex;
    align-items: center;
    padding: 0 1em;
    border-bottom: 1px lightgray solid;
    width: 100%;

    & div:nth-child(2) {
        margin-left: auto;
    }
`;

export const Logo = styled.img`
    object-fit: contain;
    opacity: 0.90;
    padding: 8px;
    border-radius: 3px;
    max-width: 150px;
    max-height: 200px;
    border-radius: 1rem;
    @media (max-width: 600px){
        max-width: 10vh;
        max-height: 10vh;
    }
`;

export const IMGLogo = styled.img`
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(13deg) brightness(105%) contrast(101%);
`;
