import React, {
    useState,
    useEffect,
    useMemo,
    useCallback
} from 'react';

import Box from '@mui/material/Box';

import useAxiosFunction from '../../hooks/useAxiosFunction';

import NewForm from '../../components/molecules/bbdd/form/New';

import { IBBDD } from '../../types/bbdd';
import ContentHeader from '../../components/atoms/structure/contentHeader';
import Table from '../../components/molecules/bbdd/table';

import * as S from './styles';

interface IProps {
    updateSidebar: () => void;
}

export const StructuresContext = React.createContext<Array<IBBDD> | []>([]);

const BBDD: React.FC<IProps> = ({ updateSidebar }: IProps) => {
    const [newPopupOpen, setNewPopupOpen] = useState<boolean>(false);

    const {
        response: structuresResponse,
        loading: structuresLoading,
        error: structuresError,
        axiosFetch: getStuctures
    } = useAxiosFunction();

    const structures: Array<IBBDD> | undefined = useMemo(() => {
        if (!structuresResponse || structuresLoading || structuresError) { return undefined; }

        return structuresResponse;
    }, [structuresResponse, structuresLoading, structuresError]);

    const fetchStructures = useCallback(() => {
        if (!getStuctures) { return; }
        getStuctures({
            url: '/api/structures',
            method: 'get'
        });
    }, [getStuctures]);

    useEffect(() => {
        if (fetchStructures) {
            fetchStructures();
        }
    }, [fetchStructures]);

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <ContentHeader
                title="BBDD"
                counter={structures?.length}
                buttonText="Nueva BBDD"
                onClick={() => {
                    setNewPopupOpen(!newPopupOpen);
                }}
            />
            { structuresLoading && <h1> Loading...</h1> }
            { !structuresLoading && !structuresError && structures && (
                <>
                    <Table
                        structures={structures}
                        refetch={() => { updateSidebar(); fetchStructures(); }}
                    />

                    <Box overflow="auto">
                        <StructuresContext.Provider value={structures}>
                            { structuresLoading && <h1> Loading...</h1> }
                            { !structuresLoading && !structuresError && (
                                <S.Container />
                            ) }

                            {newPopupOpen && (
                                <NewForm
                                    onClose={() => { setNewPopupOpen(false); }}
                                    onSubmit={() => { fetchStructures(); setNewPopupOpen(false); }}
                                    open={newPopupOpen}
                                    title="Nueva BBDD"
                                />
                            )}

                        </StructuresContext.Provider>
                    </Box>
                </>
            ) }
        </Box>
    );
};

export default BBDD;
