import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Table from '../../components/molecules/data/table';
import { URIContext } from '../../utils/URIContext';

import useAxiosFunction from '../../hooks/useAxiosFunction';

// import useAxios from '../../hooks/useAxios';

const Data: React.FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const baseURI = '/api/structures/'.concat(id || '');

    const {
        response, loading, error, axiosFetch
    } = useAxiosFunction();

    useEffect(() => {
        axiosFetch({ url: `/api/structures/${id}`, method: 'get' });
    }, [id, axiosFetch]);

    const structure = useMemo(() => {
        if (!response || loading || error) {
            return undefined;
        }
        return response;
    }, [response, loading, error]);
    // const { response } = useAxios({ url: `${baseURI}/name`, method: 'get' });

    return (
        <URIContext.Provider value={{ baseURI }}>
            {structure && <Table structure={structure} />}
        </URIContext.Provider>
    );
};

export default Data;
