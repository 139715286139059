import React from 'react';

import Form from '.';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import { IProps as ModalProps } from '../../sideModal';
import { IPlat } from '../../../../pages/platforms';

const newPlatform: IPlat = {
    name: '',
    description: '',
    subtitle: '',
    isLocked: false,
    isHidden: false,
    isFilteredByClassifier: false
};

const NewForm: React.FC<ModalProps> = ({ onSubmit, ...rest }) => {
    const { axiosFetch } = useAxiosFunction();
    const onSubmitNewAction = async (platform: IPlat) => {
        try {
            await axiosFetch({
                url: '/api/platforms',
                method: 'post',
                data: { ...platform }
            });
            onSubmit?.();
        } catch (err) {
            if (err instanceof Error) {
                console.warn(err.message);
            }
        }
    };

    return (
        <Form
            onSubmit={onSubmitNewAction}
            platform={newPlatform}
            {...rest}
        />
    );
};

export default NewForm;
