import React, {
    useRef,
    useEffect,
    useState,
    useCallback,
    useContext,
    useMemo
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
    StyledDropzone, Field, ResponsiveFlex, ResponsiveHeader, ResponsivePage
} from './styles';
import Doc from '../../components/molecules/docs/Doc';
import Folder from '../../components/molecules/docs/Folder';
import StructureFolder from '../../components/molecules/docs/StructureFolder';
import Header from '../../components/molecules/docs/Header';
import Upload from '../../components/molecules/docs/Upload';
import Path from '../../components/molecules/docs/Path';
// import StorageIndicator from '../../components/molecules/docs/StorageIndicator';

import { UserContext } from '../../App';

import useUploadFile from '../../hooks/useUploadFile';
import useAxiosFunction from '../../hooks/useAxiosFunction';

type DocType = { _id: string, originalName: string, createdAt: Date };
type FolderType = { _id: string, name: string, createdAt: Date };

const Docs: React.FC = () => {
    const [structures, setStructures] = useState<Array<{ _id: string, name: string }>>([]);
    const [folders, setFolders] = useState<Array<FolderType>>([]);
    const [files, setFiles] = useState<Array<DocType>>([]);
    const [route, setRoute] = useState<string>();
    const dropzone = useRef<HTMLDivElement>(null);
    const { folderId } = useParams();
    const [searchInput, setSearchInput] = useState<string>('');

    const navigate = useNavigate();

    const { role, platform, rootFolder } = useContext(UserContext);

    const { response: foldersResponse, axiosFetch: axiosFetchFolders } = useAxiosFunction();
    const { response: filesResponse, axiosFetch: axiosFetchFiles } = useAxiosFunction();
    const { response: structuresResponse, axiosFetch: axiosFetchStructures } = useAxiosFunction();

    const displayFiles = useMemo(() => {
        if (searchInput) {
            return files.filter((
                file
            ) => file.originalName.toLowerCase().includes(searchInput.toLowerCase()));
        }
        return files;
    }, [searchInput, files]);

    const displayFolders = useMemo(() => {
        if (searchInput) {
            return folders.filter((
                folder
            ) => folder.name.toLowerCase().includes(searchInput.toLowerCase()));
        }
        return folders;
    }, [searchInput, folders]);

    const { uploadFunction } = useUploadFile();

    const fetchFolders = () => {
        if (platform) {
            const folderQuery = folderId ? `/${folderId}/folders` : '';
            axiosFetchFolders({
                url: `/api/platforms/${platform}/folders${folderQuery}`,
                method: 'get'
            });
        }
    };

    const fetchFiles = () => {
        if (platform) {
            const urlQuery = folderId
                ? `/api/platforms/${platform}/folders/${folderId}/files`
                : `/api/platforms/${platform}/files`;

            axiosFetchFiles({
                url: urlQuery,
                method: 'get'
            });
        }
    };

    const fetchStructures = () => {
        if (platform && role === 'admin' && !folderId) {
            axiosFetchStructures({
                url: '/api/structures',
                method: 'get'
            });
        }
    };

    const updateData = () => {
        setFolders([]);
        setFiles([]);
        setStructures([]);
        fetchFolders();
        fetchFiles();
        fetchStructures();
    };

    const handleDrop = useCallback(async (e: DragEvent) => {
        if (!platform) { return; }
        const folderQuery = folderId ? `?parentId=${folderId}` : '';
        e.preventDefault();
        if (e.dataTransfer) {
            const uploadPromises = Array.from(e.dataTransfer.files)
                .map(async (file) => uploadFunction({
                    file,
                    fileType: 'doc',
                    url: `/api/platforms/${platform}/files${folderQuery}`,
                    parent: route
                }));

            await Promise.all(uploadPromises);
            updateData();
        }
    }, [route, platform]);

    useEffect(() => {
        if (dropzone.current) {
            dropzone.current.addEventListener('dragover', (e) => { e.preventDefault(); });
            dropzone.current.addEventListener('drop', handleDrop);
        }

        return () => {
            if (dropzone.current) {
                dropzone.current.removeEventListener('dragover', (e) => { e.preventDefault(); });
                dropzone.current.removeEventListener('drop', handleDrop);
            }
        };
    }, [route]);

    useEffect(() => {
        setRoute(folderId);
        setSearchInput('');
    }, [folderId]);

    useEffect(() => {
        if (foldersResponse) {
            setFolders(foldersResponse);
        } else {
            setFolders([]);
        }
    }, [foldersResponse]);

    useEffect(() => {
        if (filesResponse) {
            setFiles(filesResponse);
        } else {
            setFiles([]);
        }
    }, [filesResponse]);

    useEffect(() => {
        if (structuresResponse) {
            setStructures(structuresResponse);
        } else {
            setStructures([]);
        }
    }, [structuresResponse]);

    useEffect(() => {
        updateData();
    }, [platform, folderId]);

    useEffect(() => {
        if (rootFolder) {
            navigate(`/documentacion/${rootFolder}`);
        }
    }, [rootFolder]);

    return (
        <StyledDropzone ref={dropzone}>
            <>
                <Field style={{
                    backgroundColor: '#dadde0',
                    marginLeft: '-0.1em',
                    marginTop: '0em',
                    height: '6em',
                    width: '90%',
                    display: 'flex',
                    WebkitBorderBottomRightRadius: '2em'
                }}
                >
                    <TextField
                        id="search"
                        placeholder="Buscar por nombre de documento"
                        value={searchInput}
                        variant="outlined"
                        onChange={(e) => { setSearchInput(e.target.value); }}
                        sx={{
                            width: '80%',
                            backgroundColor: 'white',
                            borderRadius: '2em',
                            borderColor: '#dadde0',
                            '& .MuiOutlinedInput-root': {
                                '& > fieldset': {
                                    border: 'none'
                                }
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <div>
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                </div>
                            )
                        }}
                    />
                    {/*
                    <Box flexGrow={1}>
                        <StorageIndicator />
                    </Box>
                    */}
                </Field>
                <ResponsivePage sx={{ flexDirection: { xs: 'column', md: 'row' }, width: { xs: '100%', md: '80%' } }}>

                    <div>
                        <Path root={!folderId} />
                    </div>
                    <div>
                        <ResponsiveHeader sx={{ top: { md: '10px' }, width: { xs: '26rem', md: '102%' } }}>
                            <Header />
                        </ResponsiveHeader>
                        <ResponsiveFlex sx={{ flexDirection: { xs: 'column', md: 'row' }, width: { xs: '75%', md: '100%' } }}>
                            <div style={{
                                width: '100rem'
                            }}
                            >
                                {structures.map((structure) => (
                                    <StructureFolder
                                        key={structure._id}
                                        structureId={structure._id}
                                        structureName={structure.name}
                                    />
                                ))}
                                {displayFolders.map((
                                    folder: FolderType, index: number
                                ) => (
                                    <Folder
                                        key={`${folder._id}-${index.toString()}`}
                                        folderId={folder._id}
                                        folderName={folder.name}
                                        createdAt={folder.createdAt}
                                        onUpdate={updateData}
                                    />
                                ))}
                                {displayFiles.map((doc: DocType, index: number) => (
                                    <Doc
                                        key={`${doc._id}-${index.toString()}`}
                                        docId={doc._id}
                                        docName={doc.originalName}
                                        createdAt={doc.createdAt}
                                        onUpdate={updateData}
                                    />
                                ))}

                            </div>
                        </ResponsiveFlex>
                    </div>
                </ResponsivePage>
                <Upload onSubmit={updateData} />
            </>
        </StyledDropzone>
    );
};

export default Docs;
