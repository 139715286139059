import { useState, useCallback } from 'react';

import axios from '../utils/axiosIntercepter';

export interface IParams {
    url: string;
    method: 'get' | 'post' | 'options' | 'put' | 'delete';
    data?: {[x: string]: any};
    headers?: {[x:string]: any};
}

const useAxiosFunction = () => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const axiosFetch = useCallback(async (configObject: IParams) => {
        const controller = new AbortController();
        const {
            url, method, data, headers
        } = configObject;
        setLoading(true);
        setError('');
        const ctrl = new AbortController();
        try {
            const result = await axios[method](url, {
                headers,
                signal: ctrl.signal,
                data
            });
            setResponse(result.data || 'Success');
        } catch (err: any) {
            setResponse(undefined);
            if (typeof err.data === 'string') {
                setError(err.data || 'A server error has ocurred');
            } else {
                setError(() => 'A server error has ocurred');
            }
        } finally {
            setLoading(false);
        }

        return () => controller.abort();
    }, []);

    return {
        response, error, loading, axiosFetch
    };
};

export default useAxiosFunction;
