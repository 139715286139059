import React, {
    useMemo,
    useEffect,
    useContext,
    useState
} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import { UserContext } from '../../../../App';

export interface IProps {
    onLoad?: (fields: Array<{
        _id: string,
        fieldName: string,
        apiName: string,
        fieldType: string
    }>, target: string) => void;
}

const Target: React.FC<IProps> = ({ onLoad }) => {
    const [selectedTable, setSelectedTable] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);

    const { platform } = useContext(UserContext);

    const {
        response: structuresResponse,
        loading: structuresLoading,
        error: structuresError,
        axiosFetch: getStructures
    } = useAxiosFunction();

    const {
        response: structureResponse,
        loading: structureLoading,
        error: structureError,
        axiosFetch: getStructure
    } = useAxiosFunction();

    const structures: Array<{_id: string, name: string}> | undefined = useMemo(() => {
        if (!structuresResponse || structuresLoading || structuresError) { return undefined; }

        return structuresResponse;
    }, [structuresResponse, structuresLoading, structuresError]);

    const structureFields: Array<{
        _id: string,
        fieldName: string,
        apiName: string,
        fieldType: string
    }> | undefined = useMemo(() => {
        if (!selectedTable) { return undefined; }
        if (!structureResponse || structureLoading || structureError) { return undefined; }

        const filterFields = structureResponse.fields.filter((field: { fieldType: string }) => {
            if (field.fieldType === 'file') { return false; }
            if (field.fieldType === 'formula') { return false; }
            if (field.fieldType === 'autonumeric') { return false; }

            return true;
        });

        onLoad?.(filterFields, selectedTable);

        return filterFields;
    }, [structureResponse, structureLoading, structureError, selectedTable]);

    useEffect(() => {
        if (!platform || !getStructures) { return; }
        getStructures({
            method: 'get',
            url: '/api/structures?q=_id&q=name'
        });
    }, [platform, getStructures]);

    useEffect(() => {
        if (!selectedTable || !getStructure) { return; }
        getStructure({
            method: 'get',
            url: `/api/structures/${selectedTable}?q=_id&q=apiName&q=fields`
        });
    }, [selectedTable, getStructure]);

    return (
        <Box display="flex" flexDirection="column" gap="2rem">
            <Typography variant="h4" textAlign="center">
                Destino
            </Typography>
            { structures && (
                <FormControl fullWidth>
                    <InputLabel id="target-table-label">Tabla</InputLabel>
                    <Select
                        labelId="target-table-label"
                        label="Tabla"
                        value={selectedTable}
                        onChange={(event) => setSelectedTable(event.target.value as string)}
                        id="target-table-selector"
                    >
                        <MenuItem value="">
                            <em>Seleccione una tabla</em>
                        </MenuItem>
                        { structures.map((structure) => (
                            <MenuItem
                                key={structure._id}
                                value={structure._id}
                            >
                                {structure.name}
                            </MenuItem>
                        )) }
                    </Select>
                </FormControl>
            )}
            { structureFields && (
                <>
                    <TableContainer component={Paper} elevation={5}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Nombre de campo</strong>

                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>Nombre columna</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>Tipo de dato</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {structureFields
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((field) => (
                                        <TableRow
                                            key={field._id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {field.fieldName}
                                            </TableCell>
                                            <TableCell align="right">{field.apiName}</TableCell>
                                            <TableCell align="right">{field.fieldType}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={structureFields.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(_event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                    />
                </>
            )}
        </Box>
    );
};

export default Target;
