import React, { useMemo, useEffect } from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import useAxiosFunction from '../../../hooks/useAxiosFunction';

const StorageIndicator: React.FC = () => {
    const {
        response: storageResponse,
        loading: storageLoading,
        error: storageError,
        axiosFetch: getStorage
    } = useAxiosFunction();

    const used = useMemo(() => {
        if (!storageResponse || storageLoading || storageError) { return 0; }

        return storageResponse.used / 1024 / 1024 || 0;
    }, [storageResponse, storageLoading, storageError]);

    const quota = useMemo(() => {
        if (!storageResponse || storageLoading || storageError) { return 0; }

        return storageResponse.quota / 1024 / 1024 || 0;
    }, [storageResponse, storageLoading, storageError]);

    const percentageUsed = useMemo(() => {
        if (!storageResponse || storageLoading || storageError) { return 0; }

        return Math.floor((used / quota) * 100);
    }, [storageResponse, storageLoading, storageError, used, quota]);

    useEffect(() => {
        getStorage({
            method: 'get',
            url: '/api/state/user/storage'
        });
    }, [getStorage]);

    return (
        <Box display="flex" flexWrap="nowrap" alignItems="center">
            <Box width="50%" display={{ xs: 'none', md: 'block' }}>
                <LinearProgress variant="determinate" value={percentageUsed} />
            </Box>
            <Box flexGrow={1} textAlign="center">
                {(used / 1024).toFixed(2)}
                GB /
                {(quota / 1024).toFixed(2)}
                GB
            </Box>
        </Box>
    );
};

export default StorageIndicator;
