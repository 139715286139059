import { IBBDD, ISimpleEntry } from '../../../../types/bbdd';
import { HeaderType } from '.';

const isPresentFilter = (
    value: any, filters: Array<string>, ignoreIndex: Array<number>
): { match: boolean, hitIndex: number } => {
    let hit: number = -1;
    const res = filters
        .some((filter: string, index: number) => {
            if (ignoreIndex.includes(index) || hit >= 0) { return false; }
            if (value === null || value === undefined || typeof value === 'object') { return false; }
            const val: string = value.toString().toLowerCase().trim();
            const fil: string = filter.toLowerCase().trim();
            if (!fil) { return true; }
            const match = val.match(fil);
            if (match) { hit = index; }
            return match;
        });
    return { match: res, hitIndex: hit };
};

export const getDisplayedItems = (
    structure: IBBDD | undefined,
    entries: Array<ISimpleEntry> | undefined,
    searchInput: string,
    form: Array<HeaderType>
) => {
    if (!structure || !entries || !Array.isArray(entries) || !entries.length) {
        return [];
    }
    if (!form || !form.length) { return []; }
    if (!searchInput) { return entries; }
    const lowerInput: string = searchInput.toLowerCase();
    const inputSegments: Array<string> = lowerInput.split(';').filter((v) => v);
    const shown: Array<ISimpleEntry> = entries
        .filter((item: ISimpleEntry) => {
            const matches: { m: number, ignoreIndex: Array<number> } = form.reduce((
                prev: any, header: HeaderType
            ) => {
                let isMatch: { match: boolean, hitIndex: number } = {
                    match: false,
                    hitIndex: -1
                };
                if (header.type === 'field') {
                    if (header.fieldType === 'boolean') {
                        const bool: string = item[header.apiName] ? item[header.apiName].toString() : 'false';
                        isMatch = isPresentFilter(bool, inputSegments, prev.ignoreIndex);
                        return isMatch.match
                            ? {
                                m: prev.m + 1,
                                ignoreIndex: [...prev.ignoreIndex, isMatch.hitIndex]
                            }
                            : prev;
                    }
                    if (typeof item[header.apiName] === 'undefined') { return prev; }

                    if (header.fieldType === 'date' || header.fieldType === 'datetime') {
                        const dateOptions = { day: 'numeric', month: 'numeric', year: 'numeric' } as const;
                        const dateString = new Date(item[header.apiName] as string)
                            .toLocaleString('es-ES', dateOptions);
                        isMatch = isPresentFilter(dateString, inputSegments, prev.ignoreIndex);
                    } else if (header.fieldType === 'time') {
                        const timeString = new Date(item[header.apiName] as string)
                            .toLocaleTimeString('es-ES');
                        isMatch = isPresentFilter(timeString, inputSegments, prev.ignoreIndex);
                    } else if (header.fieldType === 'file') {
                        const val = item[header.apiName];
                        if (typeof val === 'object' && 'name' in val) {
                            isMatch = isPresentFilter(
                                val.name, inputSegments, prev.ignoreIndex
                            );
                        }
                    } else {
                        isMatch = isPresentFilter(
                            item[header.apiName], inputSegments, prev.ignoreIndex
                        );
                    }
                }
                if (header.type === 'relation') {
                    if (!item[header.apiName] || !header.relatedToField) { return prev; }
                    if (!header.relatedToField.apiName) { return prev; }
                    if (!Array.isArray(item[header.apiName])) { return prev; }
                    const innerItem = item[header.apiName];
                    const innerItemApiName = header.relatedToField.apiName;

                    if (!Array.isArray(innerItem)) { return prev; }
                    innerItem.forEach((inner: ISimpleEntry | string) => {
                        if (isMatch.match) { return; }
                        if (typeof inner === 'string') { return; }
                        if (!inner[innerItemApiName]) { return; }
                        isMatch = isPresentFilter(
                            inner[innerItemApiName], inputSegments, prev.ignoreIndex
                        );
                    });
                }

                return isMatch.match
                    ? { m: prev.m + 1, ignoreIndex: [...prev.ignoreIndex, isMatch.hitIndex] }
                    : prev;
            }, { m: 0, ignoreIndex: [] });

            return matches.m === inputSegments.length;
        });

    return shown;
};

export const downloadCsv = (
    structure: IBBDD | undefined,
    displayItems: Array<ISimpleEntry> | undefined,
    form: Array<HeaderType>
) => {
    if (!form || !displayItems || displayItems.length === 0) { return; }
    if (!structure || !structure.name) { return; }
    const csvHeadersString: string = form
        .map((header: HeaderType) => {
            if (!header.apiName) { return ''; }
            return header.apiName;
        })
        .join(',')
        .concat('\n');

    const csvData: Array<Array<string>> = displayItems
        .map((item: ISimpleEntry) => {
            const row: Array<string> = form.map((header) => {
                if (!header.apiName) { return ''; }
                if (header.fieldType === 'boolean') { return item[header.apiName] ? item[header.apiName].toString() : 'FALSE'; }
                if (typeof item[header.apiName] === 'undefined') { return ''; }
                if (header.fieldType === 'file') {
                    const val = item[header.apiName];
                    if (typeof val === 'object' && 'name' in val) {
                        return val.name;
                    }
                    return '';
                }
                if (header.type === 'relation') {
                    if (!header.relatedToField || !header.relatedToField.apiName) { return ''; }
                    if (!Array.isArray(item[header.apiName])) { return ''; }
                    const innerItemApiName = header.relatedToField.apiName;
                    const inner: string = (item[header.apiName] as Array<ISimpleEntry | string>)
                        .map((elem: ISimpleEntry | string) => {
                            if (typeof elem === 'string') { return elem; }
                            if (typeof elem[innerItemApiName] === 'undefined') { return ''; }
                            if (elem[innerItemApiName].toString() === '[object Object]') { return ''; }
                            return elem[innerItemApiName].toString();
                        })
                        .join(' | ');
                    return inner;
                }
                return item[header.apiName].toString();
            });

            return row;
        });

    const csvDataString: string = csvData
        .map((row: Array<string>) => row.join(','))
        .join('\n');

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,';
    hiddenElement.href += encodeURI(`${csvHeadersString}${csvDataString}`);
    hiddenElement.target = '_blank';

    hiddenElement.download = `Export_${structure.name}.csv`;
    hiddenElement.click();
};
